<template>
	<header
		class="header"
		:class="[
			getAddCard == '1' ? 'has-buttons' : '',
		]"
	>
		<div class="header__container">
			<div
				class="header__content"
			>
				<h1
					v-if="headerData?.header_video_title && getDoNotApplyH1 == '0'" 
					class="header--title text-center"
				>
					<p class="text-uppercase" v-html="headerData?.header_video_title"></p>

					<ButtonCta 
						v-if="getAddCTA == 1" 
						:target="getCTA.target" 
						:href="getCTA?.link ? getCTA?.link : ''"
						:theme="getCTA?.theme" 
						:size="getCTA?.size" 
						:label="getCTA?.label" 
						:arrow="getCTA?.arrow" 
						:class="'mt-4'"
					/>
				</h1>

				<div v-if="getAddCard == '1'" class="header__cta-rectangles cta-rectangles container">
					<div class="row">
						<div v-for="item in getCards" class="col-12 col-md-6 mb-3 mb-md-0">
							<NuxtLink
								v-if="item.header_video_cta_card_cta_target == '_self'"
								:to="$url(item.header_video_cta_card_cta_page_link)" 
								target="_self"
							>
								<div
									:style="`background: ${getCardBackground(item.header_video_cta_card_background_color, item.header_video_cta_card_background_gradient_color, item.header_video_add_card_transparency)}`"
									class="header-rectangle py-1 px-2 p-lg-3"
								>
									<div class="row">
										<div 
											class="text-start d-flex"
											:class="item.header_video_cta_card_icon_source ? 'col-10 col-md-8' : '12'"
										>
											<div 
												v-html="item.header_video_cta_card_cta_label"
												class="rectangle-label my-auto"
											></div>
										</div>

										<div
											v-if="item.header_video_cta_card_icon_source"
											class="col-2 col-md-4 text-end"
										>
											<span 
												v-html="item.header_video_cta_card_icon_source" class="header__cta-rectangles--icons cta-rectangle-icon"
											></span>
										</div>

										<div class="col-12">
											<hr>
											<span class="float-start text-white">{{ $t('discover') }}</span>
											<span class="float-end rectangle-icon">
												<ArrowIcon />
											</span>
										</div>

									</div>
								</div>
							</NuxtLink>

							<a
								v-if="item.header_video_cta_card_cta_target == '_blank'" 
								:href="item.header_video_cta_card_cta_page_link"
								target="_blank"
							>
								<div
									:style="`background: ${getCardBackground(item.header_video_cta_card_background_color, item.header_video_cta_card_background_gradient_color, item.header_video_add_card_transparency)}`"
									class="header-rectangle p-3"
								>
									<div class="row">
										<div class="text-start d-flex"
											:class="item.header_video_cta_card_icon_source ? 'col-10 col-md-8' : '12'">
											<div class="rectangle-label my-auto" v-html="item.header_video_cta_card_cta_label"></div>
										</div>

										<div v-if="item.header_video_cta_card_icon_source" class="col-2 col-md-4 text-end">
											<span class="cta-rectangle-icon" v-html="item.header_video_cta_card_icon_source"></span>
										</div>

										<div class="col-12">
											<hr>
											<span class="float-start text-white">{{ $t('discover') }}</span>
											<span class="float-end rectangle-icon">
												<ArrowIcon />
											</span>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="headerData?.header_video_title && getDoNotApplyH1 == '1'"
				class="header--title text-center h1"
			>
				<p class="text-uppercase" v-html="headerData?.header_video_title"></p>

				<ButtonCta 
					v-if="getAddCTA == 1" 
					:target="getCTA.target"
					:href="getCTA?.link ? getCTA?.link : ''"
					:theme="getCTA?.theme"
					:size="getCTA?.size"
					:label="getCTA?.label"
					:arrow="getCTA?.arrow"
					:class="'mt-4'"
				/>
			</div>

			<ResponsivePicture
				class="header--image w-100" 
				:data="getImage"
				:style="`opacity: ${isPlaying ? 0 : 1}`"
				:lazy="false"
			/>

			<div
				v-if="videoSrc" 
				class="header--video-container ratio ratio-16x9 video-wrapper" 
				style="min-height: 300px;"
			>
				<video
					v-if="videoSrc" 
					muted 
					loop 
					autoplay 
					playsinline 
					ref="video" 
					preload="auto" 
					style="object-fit: cover;"
				>
					<track src="/captions_en.vtt" kind="captions" srclang="en" label="english_captions">
					<source :src="videoSrc" type="video/mp4">
				</video>
			</div>
		</div>
	</header>
</template>

<script setup>
import { register } from 'swiper/element/bundle';
import ArrowIcon from '../../icons/arrow.vue';

register();

// ***************
// ***************
// DATA
const props = defineProps({
	data: Object
});

const headerData = props?.data?.data || {};
const getDoNotApplyH1 = headerData?.header_video_do_not_apply_h1 || '0';
const getAddCard = headerData?.header_video_cta_card || '0';
const getCards = headerData?.header_video_cta_card_repeater || {};
const getAddCTA = headerData?.header_video_cta_button || '0';

const getCTA = ref({});
const video = ref(null);
const videoSrc = ref(null);
const isMobile = ref(false);
const isPlaying = ref(false);

if (getAddCTA == '1') {
	getCTA.value = {
		target: headerData?.header_video_cta_target || '_self',
		link: headerData?.header_video_cta_page_link || headerData?.header_video_cta_file_link || '',
		label: headerData?.header_video_cta_label || '',
		theme: headerData?.header_video_cta_theme || 'primary',
		size: headerData?.header_video_cta_size || 'large',
		arrow: headerData?.header_video_add_arrow || '1',
	}
}

// ***************
// ***************
// METHODS
const getCardBackground = (solid, gradient, transparency) => {
	if (solid && gradient && transparency == '0') {
		return `linear-gradient(90deg, ${solid} 0%, ${gradient} 50%)`
	}

	if (solid && gradient && transparency == '1') {
		const r = parseInt(gradient.slice(1, 3), 16);
		const g = parseInt(gradient.slice(3, 5), 16);
		const b = parseInt(gradient.slice(5, 7), 16);
		const transparentColor = `rgba(${r}, ${g}, ${b}, 0.9)`;

		return `linear-gradient(90deg, ${solid} 0%, ${transparentColor} 80%)`
	}

	if (solid && transparency == '1') {
		const r = parseInt(solid.slice(1, 3), 16);
		const g = parseInt(solid.slice(3, 5), 16);
		const b = parseInt(solid.slice(5, 7), 16);
		const transparentColor = `rgba(${r}, ${g}, ${b}, 0.9)`;

		return `linear-gradient(90deg, ${solid} 0%, ${transparentColor} 80%)`
	}

	if (solid) {
		return `${solid}`;
	}

	return `var(--bs-gray-200)`;
}

// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log('--- HeaderVideo ---');


const getImage = computed(() => {
	let out = null;

	if (Object.keys(headerData?.header_video_image?.format || {})?.length > 0) {
		const getTitle = headerData?.header_video_image?.alt || headerData?.header_video_title;
		out = {
			...headerData.header_video_image,
			title: getTitle
		}
	}

	return out;
});

// ***************
// ***************
// LIFECYCLE
onMounted(() => {
	if (typeof window !== 'undefined') {
		const screenWidth = window.innerWidth;

		if (screenWidth < 992) {
			isMobile.value = true;
		}

		setTimeout(() => {
			if (headerData?.header_video_file) {
				videoSrc.value = headerData?.header_video_file;
				// video.value.play(); // @TODO @CHRIS not sure is this is needed.. maybe for mobile?
				isPlaying.value = true;
			}
		}, 2500)
	}
});
</script>

<style lang="scss" scoped>
.header {
	--component-min-height-offset: 400px;
	--component-max-height-offset: 200px;
	--component-padding-block-start: 75px;
	--component-padding-block-end: 75px;
	position: relative;
	display: flex;
	justify-content: center;
	height: 100%;
	min-height: calc(100vh - var(--component-min-height-offset));
	// max-height: calc(100vh - var(--component-max-height-offset));
	overflow: hidden;

	h1, .h1 {
		font-size: calc(1.2rem + 3.75vw);

    p {
      color: white;
      text-shadow: 2px 2px 8px var(--primary);
    }
  }

  .btn {
    font-family: var(--ff-sans)!important;
  }

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		background: whitesmoke;
		height: 100%;
		min-height: calc(100vh - var(--component-min-height-offset));
		width: 100%;
		max-width: 1450px;
		// max-height: calc(100vh - var(--component-max-height-offset));
	}

	&__content {
		position: relative;
		width: 100%;
		height: 100%;
		padding-block: var(--component-padding-block-start) var(--component-padding-block-end);
		z-index: 10;
	}

	&--title {
		position: relative;
		top: unset;
		left: unset;
		transform: none;
		max-width: 780px;
		margin-inline: auto;
		padding-inline: 50px;
	}

	&__cta-rectangles {
		&--icons {
			&:deep(svg) {
				width: 100%;
				max-width: 108px;
			}
		}
	}

	&--image {
		position: absolute;
		inset: 0;
		display: block;
		width: 100%;
		height: 100%;
		z-index: 8;
		opacity: 1;
		transition: all var(--ease-in) 1s;
		pointer-events: none;

		> * {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&--video-container {
		position: absolute;
		inset: 0;
		z-index: 6;
		&:before {
			pointer-events: none;
		}
	}

	// states
	&.has-buttons {
		--component-padding-block-end: 30px;
	}

	// lg +
	@include mediaq('>lg') {
		--component-min-height-offset: 300px;
		--component-max-height-offset: 200px;

		.header-rectangle .rectangle-label {
			font-size: 1.5rem;
		}
	}

	@include mediaq('>xl') {
		h1, .h1 {
			font-size: 4.375rem;
		}

		// states
		&.has-buttons {
			--component-min-height-offset: 225px;
			--component-max-height-offset: 200px;
		}

		&.has-buttons & {
			&__cta-rectangles {
				margin-block-start: 120px;
			}
		}
	}
}
</style>