<template>
	<header>
		<div class="position-relative">
			<div 
				class="container blog-header position-relative"
				:class="!isImageShow? 'pb-4 pb-lg-5' : ''"
			>
				<div class="row">
					<div class="col-12 pt-0 pt-lg-5">
						<Breadcrumb :breadcrumb="breadcrumb" rootClass="single-breadcrumb" />
						<p class="reading-time mt-4">
							{{ getContext }}  |  {{ $t('Reading Time', 'Reading Time') }}
							<span class="text-primary">
								{{ getReadTime }}
								{{ $t('minute', 'minute')}}{{ getReadTime > 1 ? 's' : '' }}
							</span>
						</p>
	
						<h1 v-html="getTitle"></h1>

						<div v-if="getImage?.[0] && !isImageShow" class="blog-img position-relative">
							<figure role="group" :aria-label="getImageTitle">
								<NuxtImg
									loading="lazy"
									:src="getImage?.[0]" 
									class="w-100" 
									:alt="getImageAlt"
								/>

								<figcaption v-if="getCaption != ''" class="small-font p-2 p-lg-3">
									<InfoIcon />
									{{ getCaption }}
								</figcaption> 
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
	import InfoIcon from '../../icons/info.vue';

	// props
	const props = defineProps({
		data: Object,
		breadcrumb: Object,
	});

	// data
	const headerData = props?.data?.data || {};
	const breadcrumb = props?.breadcrumb || {};

	const getTitle = headerData?.header_article_title || '';
	const getContext = headerData?.header_article_context || '';
	const getReadTime = headerData?.header_article_reading_minutes || 0;
	const getImage = headerData?.header_article_image?.['format']?.large || null;
	const getCaption = headerData?.header_article_image?.caption || '';
	const getImageTitle = headerData?.header_article_image?.title || getTitle;
	const getImageAlt = headerData?.header_article_image?.alt || getTitle;
	const isImageShow = headerData?.header_hide_featured_image || false;

	// computed

	// methods
	const isMobile = () => {
		if (process.client) {
			if (window.innerWidth <= 991) {
				return true
			}
		}

		return false
	};
</script>

<style lang="scss" scoped>
.top-banner {
	--gradient-start-offset: 0px;
	&__gradient {
		position: absolute;
		inset: 0;
		pointer-events: none;
		overflow: hidden;

		&--item {
			position: absolute;
			top: calc(var(--gradient-start-offset) * -1);
			height: calc(100vh - var(--gradient-start-offset));
			left: 0;
			right: 0;
		}
	}

	&__fixedImage {
		position: absolute;
		inset: 0;
		pointer-events: none;
		overflow: hidden;

		&--item {
			position: absolute;
			inset: 0;

			img {
				display: block;
				object-fit: contain;
				height: 100%;
				max-height: 450px;
				max-width: 100%;
				mask-image: radial-gradient(
					circle,
					rgba(0,0,0,1) 35%,
					rgba(0,0,0,0) 85%
				);
			}
		}
	}

	.header-text {
		position: relative;
	}

	@include mediaq('>lg') {
		// --gradient-start-offset: 162px;
	}
}

.single-breadcrumb {
	left: 11px;
}
</style>