<template>
<header>
		<div class="position-relative">
			<div 
				class="half-image-banner position-relative" 
				:style="`background: ${getBackground('outside', headerData?.header_two_columns_background_color, headerData?.header_two_columns_background_gradient_color)}`"
			>

				<Breadcrumb
					:theme="getTextColor"
					:breadcrumb="breadcrumb"
				/>

				<div class="row">
					<div
						class="col-12 col-lg-7 transparency-blur px-0 px-lg-100 text-part d-flex order-lg-1 py-lg-5 z-index-2"
						:style="`background: ${getBackground('inside', headerData?.header_two_columns_background_color, headerData?.header_two_columns_background_gradient_color)}`"
					>
						<div class="my-auto px-lg-0 py-4 py-lg-0 transparency px-5 py-3 py-lg-50">
							<div class="title">
								<span
									v-if="headerData?.header_two_columns_icon_source && getImageType !== 'url'"
									v-html="headerData.header_two_columns_icon_source">
								</span>

								<span v-if="headerData?.header_two_columns_icon_url && getImageType == 'url'">
									<NuxtImg
										loading="lazy"
										class="icon"
										:src="headerData.header_two_columns_icon_url"
									/>
								</span>

								<h1
								  v-if="getDoNotApplyH1 == '0'"
									v-html="getTitle"
									class="mb-0 text-white title"
									:class="[
										`${getTextColor ? '' : 'text-white'}`
									]"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></h1>

								<div
								  v-if="getDoNotApplyH1 == '1'"
									v-html="getTitle"
									class="mb-0 text-white title h1"
									:class="[
										`${getTextColor ? '' : 'text-white'}`
									]"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></div>

							</div>

							<div class="header-text text-white pe-lg-125">
								<p
									v-html="headerData?.header_two_columns_text"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></p>
							</div>

							<div v-if="headerData?.header_two_columns_cta_target || headerData?.header_two_columns_secondary_cta_target" class="row">
								<ButtonCta
									v-if="headerData?.header_two_columns_cta_target"
									:target="headerData?.header_two_columns_cta_target"
									:href="headerData?.header_two_columns_cta_page_link? headerData?.header_two_columns_cta_page_link : ''"
									:file="headerData?.header_two_columns_cta_page_file? headerData?.header_two_columns_cta_page_file : ''"
									:theme="headerData?.header_two_columns_cta_theme"
									:size="headerData?.header_two_columns_cta_size"
									:label="headerData?.header_two_columns_cta_label"
									:arrow="headerData?.header_two_columns_add_arrow"
									:class="'mt-4 w-auto'"
								/>
								<ButtonCta
									v-if="headerData?.header_two_columns_secondary_cta_target"
									:target="headerData?.header_two_columns_secondary_cta_target"
									:href="headerData?.header_two_columns_secondary_cta_page_link? headerData?.header_two_columns_secondary_cta_page_link : ''"
									:file="headerData?.header_two_columns_secondary_cta_page_file? headerData?.header_two_columns_secondary_cta_page_file : ''"
									:theme="headerData?.header_two_columns_secondary_cta_theme"
									:size="headerData?.header_two_columns_secondary_cta_size"
									:label="headerData?.header_two_columns_secondary_cta_label"
									:arrow="headerData?.header_two_columns_add_secondary_arrow"
									:class="'ms-sm-3 mt-4 w-auto'"
								/>
							</div>
						</div>
					</div>

					<div
						class="img-for-transparence d-block d-lg-none" 
						:style="`background: url(${headerData?.header_two_columns_image?.format?.orig[0]}) top right no-repeat;background-size: cover`"
					>
					</div>

					<div class="col-12 col-lg-5 order-lg-2 img-container py-lg-5 px-0 px-lg-100"></div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
//props
const props = defineProps({
	data: Object,
	breadcrumb: Object,
});

// data
const headerData = props?.data || {};
const breadcrumb = props?.breadcrumb || {};
const getDoNotApplyH1 = headerData?.header_two_columns_do_not_apply_h1 || '0';
let isMobile = ref(false);

onMounted(() => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 992) {
		isMobile.value = true;
	} 
});
  

// methods
const getTitle = headerData?.header_two_columns_overwrite_title ? headerData.header_two_columns_overwrite_title : headerData?.header_two_columns_title;

const getBackground = (location, solid, gradient) => {
  if (location == 'outside') {
		if (isMobile.value) {
			return '';
		} else {
			return `url(${headerData?.header_two_columns_image?.format?.orig[0]}) top center no-repeat;background-size: cover`;
		}
  }

	if (location == 'inside' && !gradient) {
		  const r = parseInt(solid.slice(1, 3), 16);
			const g = parseInt(solid.slice(3, 5), 16);
			const b = parseInt(solid.slice(5, 7), 16);
			let transparentColor = `rgba(${r}, ${g}, ${b}, 0.7)`;

      if (isMobile.value) {
				transparentColor = `rgba(${r}, ${g}, ${b}, 0.2)`;
        return `linear-gradient(to bottom, ${solid} 0%, ${solid} 80%, ${transparentColor} 100%)`;
      } else {
        return `linear-gradient(90deg, ${solid} 20%, ${transparentColor} 50%)`;
      }
  }

  if (location == 'inside' && gradient) {
		  const r = parseInt(gradient.slice(1, 3), 16);
			const g = parseInt(gradient.slice(3, 5), 16);
			const b = parseInt(gradient.slice(5, 7), 16);
			const transparentColor = `rgba(${r}, ${g}, ${b}, 0.7)`;
			if (isMobile.value) {
        return `linear-gradient(180deg, ${solid} 0%, ${transparentColor} 100%)`;
      } else {
        return `linear-gradient(90deg, ${solid} 20%, ${transparentColor} 50%)`;
      }
  }

	if (solid && gradient) {
		return `linear-gradient(90deg, ${solid} 0%, ${gradient} 50%)`;
	}

	if (solid) {
		return `${solid}`;
	}

	return `var(--bs-gray-200)`;
}

const getTextColor = headerData?.header_two_columns_text_color || null;
const getImageType = headerData?.header_two_columns_icon_output || 'url';

// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log('-- resources --');
// console.log('headerData: ', headerData);
// console.log('getImageType: ', getImageType);
</script>

<style lang="scss" scoped>
	.icon {
		max-width: 78px;
	}
	.img-for-transparence {
		height: 325px;
    margin-top: -110px;
	  z-index: 1;
	}
	.transparency-blur {
		backdrop-filter: blur(7px);
	}
	.z-index-2 {
		z-index: 2;
	}
	@include mediaq('<991px') {
	  .transparency h1, .h1 {
	    font-size: 1.7rem;
		}
	}
</style>
