<template>
	<div
	  :class="[
			!paddingTop? 'pt-4 pt-lg-50 has-padding-top' : '',
			!paddingBottom? 'pb-4 pb-lg-50 has-padding-bottom': '',
			isContact? 'bg-primary-to-bottom-right py-5' : ''
		]"
	>
		<div
		  	v-if="getEventData?.event_image?.length"
			class="blog-img position-relative"
	  	>
			<figure role="group" :aria-label="getEventData?.event_image_title">
				<img :src="getEventData?.event_image?.medium" class="w-100" :alt="getEventData?.event_image_title" />
			</figure>
		</div>
	  <div
			v-if="getEventData?.event_date_from"
			class="text-bg-primary d-inline-block mb-4 p-3"
		>
			<p
				v-if="getEventData?.event_location"
				class="mb-0"
			>
				{{ getEventData?.event_location }}
			</p>

			<span v-if="getEventData?.event_date_from">
				<template v-if="!dateAreEqual(getEventData?.event_date_to, getEventData?.event_date_from)">
					{{ $t('From') }}
				</template>
				{{ getEventData?.event_date_from }}
			</span>

			<span
				v-if="getEventData?.event_date_to && !dateAreEqual(getEventData?.event_date_to, getEventData?.event_date_from)"
				class="ms-1"
			>
				{{ $t('to') }}
				{{ getEventData?.event_date_to }}
			</span>
		</div>

		<component
			v-if="components?.length > 0"
			v-once
			v-for="(component, index) of components"
			:is="component.component"
			:data="component"
			:key="`component-${component?.name}-${index}`"
			:index="index"
			class="dynamic-block"
			:class="`${component?.name}`"
			:defaultSlug="props?.defaultSlug"
		/>

		<NewsletterForm
			v-if="emailNewslettre != '' || typeNewsletter == 'full'"
			:email="emailNewslettre"
		/>
	</div>
</template>

<script setup>
	import { defineAsyncComponent } from 'vue';

	const config = useRuntimeConfig();
	const { SITE_NAME } = config.public || {};

	const componentOverride = {
		baking: {
			'global-contacts': 'global-contacts-bu-baking'
		},
		corpo: {
			'global-contacts': 'global-contacts-bu-corpo',
			'contact-form': 'content',
		},
		lan: {
			'global-contacts': 'global-contacts-bu-lan'
		}
	}

	const route = useRoute();

	const props = defineProps({
		data: Object,
		container: Object,
		event: Object,
		defaultSlug: String,
		cssClass: Array,
	});

  let isContact = false;
  let typeNewsletter = '';

  const paddingTop = props?.container?.container_padding['rm-padding-top'] || false;
	const paddingBottom = props?.container?.container_padding['rm-padding-bottom'] ||  false;

	const getEventData = props?.event || {};

	const emailNewslettre = route?.query?.email || '';
	let componentsBase = [];

	if (typeof props?.data === 'array') {
		componentsBase = props?.data;
	}

	if (typeof props?.data === 'object') {
		Object.keys(props?.data).forEach(function(key, index) {
			const value = props?.data[key];
			if (value?.name) {
				componentsBase = [
					...componentsBase,
					value,
				]
			}
		})
	}

	const dateAreEqual = (firstDate, secondDate) => {
		return firstDate === secondDate;
	}

	const getIsOverriden = (name) => {
		if (componentOverride?.[SITE_NAME]?.[name]) {
			return componentOverride[SITE_NAME][name];
		}

		return false;
	}

	// register component
	const components = componentsBase.map((component) => {
		const {name, data, anchor} = component;
		let componentName = name;
		const contentNames = ['heading', 'paragraph', 'list', 'table', 'image', 'classic'];

		const isContent = contentNames.includes(name);
    if (name == "contact-addresses") {
      isContact = true;
    }
    if (name == "newsletter-form") {
      typeNewsletter = data.content_newsletter_form_container_type;
    }
		if (isContent) {
			componentName = 'content';
		}

		const isOverriden = getIsOverriden(componentName);
		if (isOverriden) {
			componentName = isOverriden;
		}

		if (name) {
			return {
				name: componentName,
				data: data,
				anchor: anchor,
				component: defineAsyncComponent( () => {
					return import(`./content/${componentName}.vue`)
				})
			}
		}
	}) || [];
</script>

<style lang="scss" scoped>
.is-global-contact {
	display: flex;
	flex-direction: column;

	> .dynamic-block {
		order: 2;

		&.global-contacts{
			order: 1;
		}
	}
}
</style>