<template>
	<header
	:class="`bg-${getTheme(headerData?.header_ellipse_theme)}`"
	class="position-relative"
	:style="`${getTheme(headerData?.header_ellipse_theme) == 'custom' ? 'background-color: ' + headerData?.header_ellipse_custom_background_color + ';' : ''}`">
    <div class="half-image-banner position-relative py-0 px-0">
			<Breadcrumb
				:breadcrumb="breadcrumb" 
				:theme="getTheme(headerData?.header_ellipse_theme) == 'custom' ? headerData?.header_ellipse_custom_text_color : getTextColor(headerData?.header_ellipse_theme)" 
			/>

      <div class="row">
        <div
					:class="`col-12 col-lg-${getImage != '' ? '6' : '12'} col-xl-${getImage != '' ? '5' : '12'} d-flex order-2 order-lg-1 py-0 py-lg-5 column-text`"
				>
					<div class="mt-5 px-3 px-lg-0 py-4 py-lg-0">
						<div class="title">
							<span
								v-if="headerData?.header_ellipse_icon_output && headerData?.header_ellipse_icon_output !== 'url'"
								v-html="headerData?.header_ellipse_icon_source"
								:style="`--header-ellipse-icon-color: ${getTheme(headerData?.header_ellipse_theme) == 'custom' ? headerData?.header_ellipse_custom_text_color : '#1d1d1b'};`"
								:class="`${getTheme(headerData?.header_ellipse_theme) == 'custom' ? 'header-ellipse-custom-color' : ''}`">
							</span>

							<span v-if="headerData?.header_ellipse_icon_output && headerData?.header_ellipse_icon_output == 'url'">
								<NuxtImg
									laoding="lazy"
									class="icon"
									:src="headerData.header_ellipse_icon_url"
									decoding="async"
								/>
							</span>

							<h1
							  v-if="getDoNotApplyH1 == '0'"
								v-html="headerData?.header_ellipse_title"
								class="mb-0 title"
								:class="`text-${getTextColor(headerData?.header_ellipse_theme)}`"
								:style="`${getTheme(headerData?.header_ellipse_theme) == 'custom' ? 'color: ' + headerData?.header_ellipse_custom_text_color + ';' : ''}`"
							></h1>
							<div
							  v-if="getDoNotApplyH1 == '1'"
								v-html="headerData?.header_ellipse_title"
								class="mb-0 title h1"
								:class="`text-${getTextColor(headerData?.header_ellipse_theme)}`"
								:style="`${getTheme(headerData?.header_ellipse_theme) == 'custom' ? 'color: ' + headerData?.header_ellipse_custom_text_color + ';' : ''}`"
							></div>
						</div>

						<div v-if="headerData?.header_ellipse_text" class="header-text">
							<div
								v-html="headerData?.header_ellipse_text"
								:class="`text-${getTextColor(headerData?.header_ellipse_theme)}`"
								:style="`${getTheme(headerData?.header_ellipse_theme) == 'custom' ? 'color: ' + headerData?.header_ellipse_custom_text_color + ';' : ''}`"
							></div>
						</div>

						<div v-if="headerData?.header_ellipse_cta_target || headerData?.header_ellipse_secondary_cta_target" class="row">
							<ButtonCta
							  v-if="headerData?.header_ellipse_cta_target"
								:target="headerData?.header_ellipse_cta_target"
								:href="headerData?.header_ellipse_cta_page_link? headerData?.header_ellipse_cta_page_link : ''"
								:file="headerData?.header_ellipse_cta_page_file? headerData?.header_ellipse_cta_page_file : ''"
								:theme="headerData?.header_ellipse_cta_theme"
								:size="headerData?.header_ellipse_cta_size"
								:label="headerData?.header_ellipse_cta_label"
								:arrow="headerData?.header_ellipse_add_arrow"
								:class="'mt-lg-3 w-auto'"
							/>

							<ButtonCta
							  v-if="headerData?.header_ellipse_secondary_cta_target"
								:target="headerData?.header_ellipse_secondary_cta_target"
								:href="headerData?.header_ellipse_secondary_cta_page_link? headerData?.header_ellipse_secondary_cta_page_link : ''"
								:file="headerData?.header_ellipse_secondary_cta_page_file? headerData?.header_ellipse_secondary_cta_page_file : ''"
								:theme="headerData?.header_ellipse_secondary_cta_theme"
								:size="headerData?.header_ellipse_secondary_cta_size"
								:label="headerData?.header_ellipse_secondary_cta_label"
								:arrow="headerData?.header_ellipse_add_secondary_arrow"
								:class="'ms-sm-3 mt-2 mt-sm-0 mt-lg-3 w-auto'"
							/>
						</div>

					</div>
        </div>

				<div
					v-if="getImage"
					class="col-12 col-lg-6 col-xl-7 order-1 order-lg-2 header-ellipse"
				>
					<NuxtImg
						loading="lazy"
						class="header-image"
						:src="getImage"
						:alt="headerData?.header_ellipse_image?.alt != '' ?
							headerData?.header_ellipse_image?.alt :
							headerData?.header_ellipse_title"
					/>
				</div>
      </div>
    </div>
  </header>
</template>

<script setup>
//props
const props = defineProps({
	data: Object,
	breadcrumb: Object,
});

// data
const headerData = props?.data?.data || {};
const breadcrumb = props?.breadcrumb || {};
const getDoNotApplyH1 = headerData?.header_ellipse_do_not_apply_h1 || '0';
const getImage = headerData.header_ellipse_image?.format?.large?.[0] || false;

// console.log(' ');
// console.log(' ');
// console.log('ELIPSE pageData: ', pageData);

// methods
const getBackground = (solid, gradient) => {
	if (solid && gradient) {
		return `linear-gradient(90deg, ${solid} 0%, ${gradient} 50%)`
	}

	if (solid) {
		return `${solid}`;
	}

	return `var(--bs-gray-200)`;
}
</script>

<style lang="scss" scoped>
.header-ellipse {
	max-height: 50vh;
	clip-path: none;

	.header-image {
		display: block;
		max-height: 100%;
		margin-left: auto;
		clip-path: ellipse(70% 70% at 80% 30%);
	}

	@include mediaq('>xl') {
		.header-image {
			// clip-path: ellipse(90% 67% at 94% 65%);
			clip-path: ellipse(80% 50% at 80% 50%);
			transform: translate3d(0, -5%, 0);
		}
	}
}


@media only screen and (max-width: 1200px) and (min-width: 992px)  {
  h1 {
    font-size: 3rem;
  }
}

@include mediaq('>lg') {
	header {
		.half-image-banner {
			> div:first-child {
				margin-left: -10px;
			}
		}
	}

	.column-text{
		padding-left: 62px!important;
	}
}

@include mediaq('>1079px') {
	header {
		.half-image-banner {
			> div:first-child {
				margin-left: -7px;
			}
		}
	}
	.column-text{
		padding-left: 112px!important;
	}
}
</style>