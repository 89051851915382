<template>
	<div>
		<GetHeader
			v-if="showHeader"
			:data="data?.header"
			:breadcrumb="data?.breadcrumb"
			:show="showHeader"
		/>
		
		<GetContent
			:data="data?.content"
			:container="data?.page_attributes"
			:defaultSlug="defaultSlug"
			:cssClass="[
				`${isGlobalContact() ? 'is-global-contact' : ''}`
			]"
		/>
	</div>
</template>

<script setup>
	// props
	const props = defineProps({
		data: Object,
		defaultSlug: String,
	});

	// reactive data
	const showHeader = ref(true);

	const componentData = props?.data;
	const defaultSlug = props.defaultSlug;

	// methods
	const isGlobalContact = () => {
		if (typeof props?.data?.content === 'array' && props?.data?.content?.length > 0) {
			return props?.data?.content?.some((item) => {
				return item?.name === 'global-contacts' || item?.name === 'retailer-map';
			}) || false;
		}

		if (typeof props?.data?.content === 'object') {
			let out = false;
			
			Object.keys(props?.data?.content).forEach(function(key, index) {
				const value = props?.data?.content[key];

				if (value?.name === 'global-contacts' || value?.name === 'retailer-map' || value?.name === 'categorized-map') {
					out = true;
				}
			})

			return out;
		}
	}

	// lifecyle
	onMounted(() => {
		if (isGlobalContact()) {
			showHeader.value = false;
		}
	})
</script>

<style lang="scss" scoped>
</style>