<template>
	<div>
		<header>
			<div class="position-relative">
				<div 
					class="home-banner text-center px-3 px-lg-100"
					:style="`background-color: ${headerData?.header_animated_background_color}`"
				>
					<h1 
						class="mx-auto text-uppercase" 
						v-if="headerData?.header_animated_title && getDoNotApplyH1 == '0'"
						v-html="headerData?.header_animated_title"
					></h1>
					<div 
						class="mx-auto text-uppercase h1" 
						v-if="headerData?.header_animated_title && getDoNotApplyH1 == '1'"
						v-html="headerData?.header_animated_title"
					></div>
	
					<NuxtImg
						v-if="headerData?.header_animated_fourth_image?.format?.medium?.[0]"
						loading="lazy"
						:src="headerData?.header_animated_fourth_image?.format?.medium?.[0]" 
						decoding="async" 
						width="200" 
						class="bacteria-2" 
						alt=""
					/>
					<NuxtImg
						v-if="headerData?.header_animated_second_image?.format?.medium?.[0]"
						loading="lazy"
						:src="headerData?.header_animated_second_image?.format?.medium?.[0]" 
						decoding="async" 
						width="200"
						class="bacteria-3"
						alt=""
					/>
					<NuxtImg
						v-if="headerData?.header_animated_third_image?.format?.medium?.[0]"
						loading="lazy"
						:src="headerData?.header_animated_third_image?.format?.medium?.[0]" 
						decoding="async" 
						width="259" 
						class="bacteria-4" 
						alt=""
					/>
				</div>
	
				<NuxtImg 
					v-if="headerData?.header_animated_first_image?.format?.medium?.[0]"
					loading="lazy"
					:src="headerData?.header_animated_first_image?.format?.medium?.[0]" 
					decoding="async" 
					width="200" 
					class="bacteria-1" 
					alt=""
				/>
			</div>
		</header>
	</div>
</template>

<script setup>
	const props = defineProps({
		data: Object
	});

	// data
	const headerData = props?.data?.data || {};
	const getDoNotApplyH1 = headerData?.header_animated_do_not_apply_h1 || '0';

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('headerData: ', headerData);
</script>