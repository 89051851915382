<template>
	<div
		class="top-banner ellipse-banner px-4 px-lg-100"
		:style="getRootStyleThemeColor"
	>
	  <Breadcrumb :breadcrumb="breadcrumb" />

		<NuxtImg
			v-if="screenWidth >= 991"
			class="ellipse-banner__backgroundImage"
			loading="lazy"
			src="/ellipse-desktop.svg"
		/>
		
		<NuxtImg
			v-if="screenWidth <= 990"
			class="ellipse-banner__backgroundImage"
			loading="lazy"
			src="/ellipse-mobile.svg"
		/>

    <div class="ellipse-banner__content container">
      <div class="row g-5 align-items-center">
        <div class="col-lg-5">
          <div class="header-text text-center text-lg-start">
            <h1 v-if="getTitle && getDoNotApplyH1 == '0'" v-html="getTitle" class="text-white"></h1>
						<div v-if="getTitle && getDoNotApplyH1 == '1'" v-html="getTitle" class="text-white h1"></div>

            <p v-if="getText" v-html="getText" class="text-white mx-0"></p>
          </div>
        </div>

        <div class="col-lg-6 offset-lg-1 position-relative">
          <div class="card-form px-3 py-4 px-lg-4">
						<div v-if="errorfullySubmitted" class="alert alert-danger text-center" role="alert">
							{{ $t('error_submit_form') }}
						</div>

            <div
							v-if="successfullySubmitted"
							class="alert alert-success text-center"
							role="alert"
						>
						 {{ $t('contact_thank_you') }}
						</div>

            <form v-else @submit.prevent="checkForm" method="post">
              <div class="row">
								<!-- fieldFirstName -->
                <div class="col-lg-6 form-floating">
                  <input
										type="text"
										class="form-control"
										id="firstName"
										:placeholder="$t('first_name')"
										:class="{ 'is-invalid': formFields.fieldFirstName === '' && submitedOnce}"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldFirstName',
											required: true,
										})"
										required
									/>
                  <label for="firstName" class="form-label">
										{{ $t('first_name') }}*
									</label>
                </div>

								<!-- fieldLastName -->
                <div class="col-lg-6 form-floating">
                  <input
										type="text"
										class="form-control"
										id="lastName"
										:class="{ 'is-invalid': formFields.fieldLastName === '' && submitedOnce }"
										:placeholder="$t('last_name')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldLastName',
											required: true,
										})"
										required
									>
                  <label
										for="lastName"
										class="form-label"
									>{{ $t('last_name') }}*</label>
                </div>

								<!-- fieldEmail -->
                <div class="col-lg-6 form-floating">
                  <input
										type="email"
										class="form-control"
										id="email"
										:class="{ 'is-invalid': !validateEmail(formFields.fieldEmail) && submitedOnce }"
										:placeholder="$t('email')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldEmail',
											required: true,
										})"
										required
									>
                  <label
										for="email"
										class="form-label"
									>{{ $t('email') }}*</label>
                </div>

								<!-- fieldPhone -->
                <div class="col-lg-6 form-floating">
                  <input
										type="text"
										class="form-control"
										id="phone"
										:placeholder="fieldsConfig.fieldPhone.label"
										:required="fieldsConfig.fieldPhone.required"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldPhone',
											required: fieldsConfig.fieldPhone.required,
										})"
									>
                  <label
										for="phone"
										class="form-label"
									>
										<template v-if="!fieldsConfig.fieldPhone.required">
											{{ $t('phone') }}
											<span class="text-half-primary small-font">({{ $t('optional') }})</span>
										</template>

										<template v-if="fieldsConfig.fieldPhone.required">
											{{ $t('phone') }}*
										</template>
									</label>
                </div>

								<!-- fieldCompany -->
                <div class="col-lg-6 form-floating">
                  <input
										type="text"
										class="form-control"
										id="company"
										:class="{ 'is-invalid': formFields.fieldCompany === '' && submitedOnce }"
										:placeholder="$t('company')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldCompany',
											required: true,
										})"
										required
									>
                  <label for="company" class="form-label">{{ $t('company') }}*</label>
                </div>

				<!-- fieldFunction -->
				<div  v-if="SITE_NAME == 'baking'" class="col-lg-6 form-floating">
					<select
						class="form-select form-control w-100"
						required
						:aria-label="$t('baking_function')"
						@input="event => updateField({
							value: event.target.value,
							name: 'fieldBakingFunction',
							required: true,
						})"
						id="bakingFunction"
					>
						<option selected disabled value="">{{ $t('baking_function') }}*</option>
						<option value="Accounting">{{ $t('baking_function_accounting') }}</option>
						<option value="Customer Service">{{ $t('baking_function_customer_service') }}</option>
						<option value="Management">{{ $t('baking_function_management') }}</option>
						<option value="Marketing">{{ $t('baking_function_marketing') }}</option>
						<option value="Procurement">{{ $t('baking_function_procurement') }}</option>
						<option value="Product Management">{{ $t('baking_function_product_management') }}</option>
						<option value="Production">{{ $t('baking_function_production') }}</option>
						<option value="Quality Control">{{ $t('baking_function_quality_control') }}</option>
						<option value="Regulatory Affairs">{{ $t('baking_function_regulatory_affairs') }}</option>
						<option value="Research & Development">{{ $t('baking_function_research_development') }}</option>
						<option value="Sales">{{ $t('baking_function_sales') }}</option>
						<option value="Specialist">{{ $t('baking_function_specialist') }}</option>
						<option value="Technical Sales Support">{{ $t('baking_function_technical_sales_support') }}</option>
						<option value="Warehouse Manager">{{ $t('baking_function_warehouse_manager') }}</option>
						<option value="Other Function">{{ $t('baking_function_other_function') }}</option>
					</select>
				</div>

								<!-- fieldFunction -->
								<div  v-if="SITE_NAME == 'baking'" class="col-lg-6 form-floating">
									<select
										class="form-select form-control w-100"
										required
										:aria-label="$t('baking_function')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldBakingFunction',
											required: true,
										})"
										id="bakingFunction"
									>
										<option selected disabled value="">{{ $t('baking_function') }}*</option>
										<option value="Accounting">{{ $t('baking_function_accounting') }}</option>
										<option value="Customer Service">{{ $t('baking_function_customer_service') }}</option>
										<option value="Management">{{ $t('baking_function_management') }}</option>
										<option value="Marketing">{{ $t('baking_function_marketing') }}</option>
										<option value="Procurement">{{ $t('baking_function_procurement') }}</option>
										<option value="Product Management">{{ $t('baking_function_product_management') }}</option>
										<option value="Production">{{ $t('baking_function_production') }}</option>
										<option value="Quality Control">{{ $t('baking_function_quality_control') }}</option>
										<option value="Regulatory Affairs">{{ $t('baking_function_regulatory_affairs') }}</option>
										<option value="Research & Development">{{ $t('baking_function_research_development') }}</option>
										<option value="Sales">{{ $t('baking_function_sales') }}</option>
										<option value="Specialist">{{ $t('baking_function_specialist') }}</option>
										<option value="Technical Sales Support">{{ $t('baking_function_technical_sales_support') }}</option>
										<option value="Warehouse Manager">{{ $t('baking_function_warehouse_manager') }}</option>
										<option value="Other Function">{{ $t('baking_function_other_function') }}</option>
									</select>
								</div>

								<!-- fieldCountry -->
                <div class="col-lg-6 form-floating">
									<select
										class="form-select form-control w-100"
										required
										:aria-label="$t('select_country')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldCountry',
											required: true,
										})"
										id="country"
									>
										<option selected disabled value="">{{ $t('country') }}*</option>
										<option
											v-for="(value, key) in getSortedCountries()"
											:key="key"
											:value="value"
											v-html="value"
										>
										</option>
									</select>
                </div>

                <!-- fieldBuSector -->
                <div  v-if="SITE_NAME == 'lhs'" class="col-lg-6 form-floating">
                  <select
                  class="form-select w-100 form-control"
                  :aria-label="$t('select_function')"
                  v-model="formFields.fieldBuSector"
                  id="buSector"
                  required
                >
                  <option value="">{{ $t('lhs_bu_sector') }}*</option>
                  <option value="Dietary supplements">{{ $t('lhs_dietary_supplements') }}</option>
                  <option value="Foods and beverages">{{ $t('lhs_frosen') }}</option>
                  <option value="Infant formula">{{ $t('lhs_infant_formula') }}</option>
                  <option value="Ingredients supplier">{{ $t('lhs_ingredients_supplier') }}</option>
                  <option value="Pet supplements">{{ $t('lhs_supplements') }}</option>
                  <option value="Pharmaceutical products">{{ $t('lhs_pharmaceutical_products') }}</option>
                  <option value="Research and Development">{{ $t('lhs_rd') }}</option>
                  <option value="Services or Equipment supplier">{{ $t('lhs_service') }}</option>
                  <option value="Other">{{ $t('other') }}</option>
                </select>
								</div>

								<!-- fieldDepartment -->
								<div  v-if="SITE_NAME == 'lhs'" class="col-lg-6 form-floating">
								  <select
										class="form-select w-100 form-control"
										:aria-label="$t('select_function')"
										v-model="formFields.fieldDepartment"
										id="department"
										required
									>
                  <option value="">{{ $t('lhs_department') }}*</option>
                  <option value="Academic">{{ $t('lhs_academic') }}</option>
                  <option value="Administration and Finance">{{ $t('lhs_administration') }}</option>
                  <option value="Consultancy">{{ $t('lhs_consultancy') }}</option>
                  <option value="Engineering">{{ $t('lhs_engineering') }}</option>
                  <option value="General Management">{{ $t('lhs_general_management') }}</option>
                  <option value="Marketing / Communications">{{ $t('lhs_marketing') }}</option>
                  <option value="Production / Manufacturing">{{ $t('lhs_production') }}</option>
                  <option value="Purchasing">{{ $t('lhs_purchasing') }}</option>
                  <option value="Regulatory Affairs">{{ $t('lhs_regulatory_affairs') }}</option>
                  <option value="Research/NPD/Science">{{ $t('lhs_research') }}</option>
                  <option value="Sales/Business development">{{ $t('lhs_sales') }}</option>
                  <option value="Quality Assurance/Quality Control">{{ $t('lhs_quality') }}</option>
                  <option value="Other">{{ $t('other') }}</option>
                </select>
								</div>

								<!-- fieldState -->
								<div
									v-if="formFields.fieldCountry === 'United States of America'"
									class="col-lg-6 form-floating"
								>
									<select
										class="form-select form-control w-100"
										required
										:aria-label="$t('state')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldState',
											required: true,
										})"
										id="state"
									>
										<option selected disabled value="">{{ $t('state') }}*</option>
										<option
											v-for="(option, index) in getStates()"
											:key="`state-${index}`"
											:value="option.value"
										>
											{{ option.label }}
										</option>
									</select>
                </div>

								<!-- fieldPrefecture -->
								<div
									v-if="formFields.fieldCountry === 'Japan'"
									class="col-lg-6 form-floating"
								>
									<select
										class="form-select form-control w-100"
										required
										:aria-label="$t('prefecture')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldPrefecture',
											required: true,
										})"
										id="prefecture"
									>
										<option selected disabled value="">{{ $t('prefecture') }}*</option>
										<option
											v-for="(option, index) in getPrefectures()"
											:key="`prefecture-${index}`"
											:value="option.value"
										>
											{{ option.label }}
										</option>
									</select>
								</div>

								<!-- CORPO - fieldBusinessUnit -->
								<div
									v-if="SITE_NAME === 'corpo'"
									class="col-lg-12 form-floating"
								>
									<select
										class="form-select form-control w-100"
										required
										:aria-label="$t('corpo_select_business_unit')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldBusinessUnit',
											required: true,
										})"
										id="businessUnit"
									>
										<option value="">{{ $t('corpo_business_unit') }}*</option>
										<option value="2050">{{ $t('corpo_business_unit_animal_nutrition') }}</option>
										<option value="15">{{ $t('corpo_business_unit_baking') }}</option>
										<option value="2032">{{ $t('corpo_business_unit_biofuels_distilled_spirits') }}</option>
										<option value="2020">{{ $t('corpo_business_unit_brewing') }}</option>
										<option value="1">{{ $t('corpo_business_unit_corporate') }}</option>
										<option value="2075">{{ $t('corpo_business_unit_cosmetics_beauty') }}</option>
										<option value="2040">{{ $t('corpo_business_unit_health_solutions') }}</option>
										<option value="2010">{{ $t('corpo_business_unit_oenology') }}</option>
										<option value="2080">{{ $t('corpo_business_unit_pharma') }}</option>
										<option value="2085">{{ $t('corpo_business_unit_plant_care') }}</option>
										<option value="2035">{{ $t('corpo_business_unit_specialty_cultures') }}</option>
									</select>
	            	</div>

								<!-- fieldYouAre -->
                <div
									v-if="SITE_NAME === 'brewing'"
									class="col-12 my-3"
								>
                  <select
										class="form-select w-100"
										required
										:aria-label="$t('select_function')"
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldYouAre',
											required: true,
										})"
										id="youAre"
									>
                    <option value="">{{ $t('you_are') }}*</option>
										<option value="Business Owner">{{ $t('brew_you_are_business_owner') }}</option>
                    <option value="Craft Brewer">{{ $t('brew_you_are_craft_brewer') }}</option>
                    <option value="Home Brewer">{{ $t('brew_you_are_home_brewer') }}</option>
                    <option value="Brewer">{{ $t('brew_you_are_brewer') }}</option>
                    <option value="Home Wine Maker">{{ $t('brew_you_are_home_wine_maker') }}</option>
                    <option value="Bar and restaurant owner or employee">{{ $t('brew_you_are_bar_restaurant') }}</option>
                    <option value="Sales / Marketing employee">{{ $t('brew_you_are_sales_marketing') }}</option>
                    <option value="Production employee">{{ $t('brew_you_are_production') }}</option>
                    <option value="Administration employee">{{ $t('brew_you_are_administration') }}</option>
                    <option value="R&D / QC Scientist">{{ $t('brew_you_are_rd_scientist') }}</option>
                    <option value="Journalist">{{ $t('brew_you_are_journalist') }}</option>
                    <option value="Influencer / Sommeliers">{{ $t('brew_you_are_influencer_sommelier') }}</option>
                    <option value="Enthusiast">{{ $t('brew_you_are_enthusiast') }}</option>
                    <option value="Other">{{ $t('other') }}</option>
                  </select>

                </div>


				<!-- BAKING - fieldTypeOfBakery -->
				<div
					v-if="SITE_NAME === 'baking'"
					class="col-lg-6 form-floating"
				>
					<select
						class="form-select form-control w-100"
						required
						:aria-label="$t('baking_select_type_of_bakery')"
						@input="event => updateField({
							value: event.target.value,
							name: 'fieldTypeOfBakery',
							required: true,
						})"
						id="typeOfBakery"
					>
						<option value="">{{ $t('baking_type_of_bakery') }}*</option>
						<option value="Artisinal">{{ $t('baking_type_of_bakery_artisinal') }}</option>
						<option value="Retail">{{ $t('baking_type_of_bakery_retail') }}</option>
						<option value="Industrial">{{ $t('baking_type_of_bakery_industrial') }}</option>
						<option value="Other">{{ $t('baking_type_of_bakery_other') }}</option>
					</select>
				</div>
				<!-- fieldTypeOfBakery -->


				<!-- BAKING - fieldBakingProductInterests -->
				<div
					v-if="SITE_NAME === 'baking'"
					class="col-lg-12 form-floating"
				>
					<div class="w-100 h6 mb-1 mt-3">{{ $t('baking_product_interests') }} *</div>
					<div v-if="errorBakingProductInterests" class="invalid-feedback mt-1 d-block">{{ $t('baking_select_product_interests') }}</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Baker's Yeast"
							/>
							{{ $t('baking_product_interests_bakers_yeast') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Conditioners"
							/>
							{{ $t('baking_product_interests_conditioners') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Shelf-life"
							/>
							{{ $t('baking_product_interests_shelf_life') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Tolerance"
							/>
							{{ $t('baking_product_interests_tolerance') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Bake Time Reduction"
							/>
							{{ $t('baking_product_interests_bake_time_reduction') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Emulsifier Replacement"
							/>
							{{ $t('baking_product_interests_emulsifier_replacement') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Gluten Replacement"
							/>
							{{ $t('baking_product_interests_gluten_replacement') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Sugar Management"
							/>
							{{ $t('baking_product_interests_sugar_management') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Frozen Process"
							/>
							{{ $t('baking_product_interests_frozen_process') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Artisanal Range"
							/>
							{{ $t('baking_product_interests_artisanal_range') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Inactive Yeast"
							/>
							{{ $t('baking_product_interests_inactive_yeast') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Teste, Aroma, Flavour, and Color"
							/>
							{{ $t('baking_product_interests_taste_aroma_flavor_and_color') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Nutritional Solutions"
							/>
							{{ $t('baking_product_interests_nutritional_solutions') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Mold Inhibitors"
							/>
							{{ $t('baking_product_interests_mold_inhibitors') }}
						</label>
					</div>
					<div class="form-check form-check-inline w-100">
						<label>
							<input
								class="form-check-input"
								type="checkbox"
								id="bakingProductInterests"
								v-model="checkBakingProductInterests"
								value="Other"
							/>
							{{ $t('baking_product_interests_other') }}
						</label>
					</div>
				</div>
				<!-- fieldBakingProductInterests -->


				<!-- fieldComment -->
                <div class="col-12 mb-2">
                  <label
										for="message"
										class="h6 mb-1 mt-3"
									>
										{{ fieldsConfig.fieldComment.label }}
										{{ fieldsConfig.fieldComment.required ? '*' : '' }}
									</label>

                  <textarea
										@input="event => updateField({
											value: event.target.value,
											name: 'fieldComment',
											required: fieldsConfig.fieldComment.required,
										})"
										id="message"
										rows="12"
										class="form-control"
										:required="fieldsConfig.fieldComment.required"
									></textarea>
                </div>

								<!-- fieldContacted & fieldNewsletter -->
                <div class="col-12 mb-3">
                  <div class="form-check form-check-inline">
                    <input
											class="form-check-input"
											type="checkbox"
											id="contacted"
											@input="event => updateField({
												value: event.target.checked,
												name: 'fieldContacted',
												type: 'checkbox',
												required: fieldsConfig.fieldContacted.required,
											})"
											:required="fieldsConfig.fieldContacted.required"
										>

                    <label
											class="form-check-label"
											for="contacted"
										>
											{{ fieldsConfig.fieldContacted.label }}
											{{ fieldsConfig.fieldContacted.required ? '*' : '' }}
										</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
											class="form-check-input"
											type="checkbox"
											id="newsletter"
											@input="event => updateField({
												value: event.target.checked,
												type: 'checkbox',
												name: 'fieldNewsletter',
											})"
										>
                    <label class="form-check-label" for="newsletter">{{ $t('consent_newsletter') }}</label>
                  </div>
                </div>

								<!-- captchaVerified -->
                <div class="col-12 mb-3 recaptcha-wrapper">
									<vue-recaptcha ref="recaptcha"
										:sitekey="RECAPTCHA_SITE_KEY"
										@verify="onRecaptchaVerify"
										@expired="onRecaptchaVerify"
									/>

									<div
										v-if="captchaVerified"
										id="error-captcha"
										class="invalid-feedback d-block mt-2"
									>
										{{ $t('validation_captcha') }}
									</div>
                </div>
              </div>

              <button
								type="submit"
								class="btn btn-primary btn-icon w-sm-100"
								:disabled="isSubmitting"
							>
								{{ isSubmitting ? $t('sending') : $t('send') }}
								<span class="icon-arrow"><ArrowIcon /></span>
							</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
	import { VueRecaptcha } from 'vue-recaptcha';

	import {
		getBackendEndpoint, 
		getCountries, 
		getPrefectures, 
		getTheme 
	} from '#imports';

	import ArrowIcon from '../../icons/arrow.vue';

	const config = useRuntimeConfig();
	const {
		ACTIVE_MARKET,
		API_SITE_ENDPOINT,
		API_SITE_URL,
		ENVIRONMENT,
		GTM_KEY,
		NUXT_PUBLIC_GTAG_ID,
		RECAPTCHA_SITE_KEY,
		SITE_NAME,
		SITE_URL,
		TYPE
	} = config?.public || {};

	const { locale, t } = useI18n();
	const route = useRoute();
	const cookie = useCookie(SITE_NAME + '-cookie_analytical');

	// ***************
	// ***************
	// METHODS
	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	};

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}

			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	// ***************
	// ***************
	// DATA
	const props = defineProps({
		data: Object,
		breadcrumb: Object,
	});

	// reactive
	let successfullySubmitted = ref(false);
	let errorfullySubmitted = ref(false);
	let errorBakingProductInterests = ref(false);

	const missingCaptchaDefaultValue = ENVIRONMENT === 'local' ? false : true; // false on local
	const captchaVerified = ref(false);
	const missingCaptcha = ref(missingCaptchaDefaultValue);
	const submitedOnce = ref(false);
	let isSubmitting = ref(false);

	const screenWidth = ref(0);


	// form
	const formFields = ref({
		fieldFirstName: '',
		fieldLastName: '',
		fieldEmail: '',
		fieldPhone: '',
		fieldCompany: '',
		fieldBusinessUnit: '',
		fieldBakingFunction: '',
		fieldCountry: '',
		fieldState: '',
		fieldPrefecture: '',
		fieldContacted: false,
		fieldNewsletter: false,
		fieldYouAre: '',
		fieldTypeOfBakery: '',
		fieldComment: '',
		fieldBuSector: '',
    	fieldDepartment: ''
	});
	const checkBakingProductInterests = ref([]);
	let BakingProductInterests = {};

	const fieldsConfig = ref({
		fieldPhone: {
			label: t('phone_optional'),
			required: false,
		},
		fieldComment: {
			label: t('message_explanation'),
			required: true,
		},
		fieldContacted: {
			label: t('consent_contacted'),
			required: true,
		}
	});

	const componentData = props?.data?.data || {};
	const breadcrumb = props?.breadcrumb || {};
	const getDoNotApplyH1 = componentData?.header_contact_us_do_not_apply_h1 || '0';

	// computed
	const getTitle = componentData?.header_contact_us_title || '';
	const getText = componentData?.header_contact_us_text || '';
	const theme = componentData?.header_contact_us_theme || 'primary';

	const cookieIsAllowed = cookie?.value === true;

	// ***************
	// ***************
	// METHODS
	const onRecaptchaVerify = (response) => {
		if (response) {
			missingCaptcha.value = false;
		} else {
			missingCaptcha.value = true;
		}
	};

	const validateEmail = (email) => {
		if (!email) {
			return false;
		}

		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	}

	const updateField = ({
		value = '',
		name = '',
		type = 'text',
		required = false
	}) => {

		if (type === 'text' && formFields?.value?.[name] !== undefined) {
			formFields.value[name] = value;
		}

		if (type === 'checkbox' && formFields?.value?.[name] !== undefined) {
			formFields.value[name] = value;
		}
	}

	const getPageURL = () => {
		return `${SITE_URL}${route.path}`;
	}

	const checkForm = async (event) => {
		event.preventDefault();
		submitedOnce.value = true;

		if (SITE_NAME === 'baking') {
			if (checkBakingProductInterests.value.length > 0) {
				errorBakingProductInterests.value = false;
			} else {
				errorBakingProductInterests.value = true;
			}
		}

		if (missingCaptcha.value) {
			captchaVerified.value = true;
		}

		if (!captchaVerified.value && !errorBakingProductInterests.value) {
			// handle province / state / prefecture
			let province_state_prefecture = '';
			if (formFields.value.fieldCountry === 'United States of America') {
				province_state_prefecture = formFields.value.fieldState;
			}

			if (formFields.value.fieldCountry === 'Japan') {
				province_state_prefecture = formFields.value.fieldPrefecture;
			}

			// form data object
			const formData = {
				firstName: formFields.value.fieldFirstName,
				lastName: formFields.value.fieldLastName,
				email: formFields.value.fieldEmail,
				company: formFields.value.fieldCompany,
				phone: formFields.value.fieldPhone,
				country: formFields.value.fieldCountry,
				bu_code: formFields.value.fieldBusinessUnit,
				province_state: province_state_prefecture,
				youAre: formFields.value.fieldYouAre,
				TypeOfBakery: formFields.value.fieldTypeOfBakery,
				BakingProductInterests: checkBakingProductInterests.value,
				message: formFields.value.fieldComment,
				contacted: formFields.value.fieldContacted,
				newsletter: formFields.value.fieldNewsletter,
				buSector: formFields.value.fieldBuSector,
				department: formFields.value.fieldDepartment,
				url: getPageURL(),
			};

			if (SITE_NAME === 'baking') {
				formData.bakingFunction = formFields.value.fieldBakingFunction;
			}

			try {
				isSubmitting.value = true;
				const endpointUrl = () => {
					if (import.meta.client && TYPE == 'static') {
						const contactFormBackendEndpointUrl = getBackendEndpoint({
							activeMarket: getActiveMarketBoolean(),
							locale: locale.value,
							name: 'form',
							pageName: null,
							siteEndpoint: API_SITE_ENDPOINT,
							siteUrl: API_SITE_URL,
						});

						return contactFormBackendEndpointUrl;
					}

					if (getActiveMarketBoolean()) {
						return `/api/form?locale=${locale.value}&market=${getCurrentMarketSlug()}`;
					}

					return `/api/form?locale=${locale.value}`;
				}

				console.log(' ')
				console.log(' ')
				console.log(' ')
				console.log(' ')
				console.log(' ')
				console.log('--- SENDING FORM ---');
				console.log('endpointUrl() ', endpointUrl());
				console.log('formData: ', formData);

				const response = await $fetch(`${endpointUrl()}`, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify({
						form: 'Contact',
						values: formData,
					}),
				});

				if (response.form_entry == 1) {
					successfullySubmitted.value = true;

					if (cookieIsAllowed) {
						// if ONLY gtm
						if ( process.client && ( GTM_KEY !== null && GTM_KEY !== '' ) && ( NUXT_PUBLIC_GTAG_ID == null || NUXT_PUBLIC_GTAG_ID == '') ) {
							sendDatalayer();
						}

						// GTAG
						if ( process.client && ( NUXT_PUBLIC_GTAG_ID !== null && NUXT_PUBLIC_GTAG_ID !== '' ) ) {
							sendDatalayer();
						}
					}
				} else {
					errorfullySubmitted.value = true;
				}

				isSubmitting.value = false;
			}
			catch (error) {
				// Handle any errors that occur during the request
				console.error('Error occurred while submitting form', error);
			}
		}
	};

	const getSortedCountries = () => {
		let list = [];

		Object.keys(getCountries())?.forEach(function(k, i){
			list = [...list, k];
		});

		return list.sort();
	}

	const getRootStyleThemeColor = computed(() => {
		if (SITE_NAME === 'baking') {
			return `--theme-color: var(--corpo)`
		}

		return `--theme-color: var(--${getTheme(theme)})`
	});

	const getScreenWidth = () => {
		if (import.meta.client) {
			return window.innerWidth
		}

		return 0
	};

	// ***************
	// ***************
	// DATALAYER
	const sendDatalayer = () => {
		// prepare base object used for the data layer
		let eventTypeData = {};
		let eventData = {};

		// type
		eventTypeData.event = 'general_contact_form';
		// data
		eventData = {
			'country_selected': formFields.value.fieldCountry,
		}

		const mergeDatalayer = {
			data: {
				...eventTypeData,
				...eventData
			}
		}

		datalayer(mergeDatalayer);
	}

	// ***************
	// ***************
	// lifecycle
	if (SITE_NAME === 'baking') {
		// fieldPhone
		fieldsConfig.value.fieldPhone.label = t('phone');
		fieldsConfig.value.fieldPhone.required = true;

		// fieldContacted
		fieldsConfig.value.fieldContacted.required = false;
	}

	onMounted(() => {
		screenWidth.value = getScreenWidth();
	})
</script>

<style lang="scss" scoped>
.ellipse-banner {
	position: relative;
	background-color: var(--theme-color);

	&__backgroundImage {
		position: absolute;
		inset: 0;
		min-width: 100%;
		height: 27%;
	}

	// md +
	@include mediaq('>md') {
		&__backgroundImage {
			left: 50%;
			min-width: 93%;
			width: 93%;
			height: auto;
			transform: translate3d(-50%, 0, 0);
		}
	}

	// 1250px +
	@include mediaq('>1250px') {
		&__backgroundImage {
			top: 0;
			min-width: 95%;
			width: 95%;
			transform: translate3d(-50%, 0, 0);
		}
	}

	// 1500px +
	@include mediaq('>1500px') {
		&__backgroundImage {
			top: 0;
			left: 4%;
			min-width: 75%;
			width: 75%;
			transform: translate3d(0, 0, 0);
		}
	}
}
</style>