<template>
  <NuxtLink
    v-if="targetData == '_self'"
    :to="getUrlWithQuery(hrefData)"
    class="btn btn-sm-mobile"
    :class="[
			`btn-${themeData}`, 
			sizeData, 
			arrowData == '1' ? 'btn-icon icon-end': '', 
			classData, 
			arrowDown ? 'aclickCtaButtonrrow-down' : ''
		]"
    target="_self"
		@click="clickCtaButton"
  >
    <span>
			{{ labelData }}&nbsp;
		</span>
		
		<ArrowIcon v-if="arrowData == 1" />
  </NuxtLink>

  <a
    v-if="targetData == '_blank'"
    :href="hrefData"
    class="btn btn-sm-mobile"
    :class="[
			`btn-${themeData}`, 
			sizeData, 
			arrowData == '1' ? 'btn-icon icon-end': '', 
			classData, 
			arrowDown ? 'arrow-down' : ''
		]"
    target="_blank"
		@click="clickCtaButton"
  >
    <span>
			{{ labelData }}&nbsp;
		</span>
		
		<ArrowIcon v-if="arrowData == 1" />
  </a>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
	import { datalayer, slugify } from '#imports';
	import ArrowIcon from '../../icons/arrow.vue';
	
	const { $url } = useNuxtApp();
	const config = useRuntimeConfig();
	const { SITE_NAME } = config?.public || {};

	const route = useRoute();

	const cookie = useCookie(SITE_NAME + '-cookie_analytical');
	const cookieIsAllowed = cookie?.value === true;

	const datalayerStore = useDatalayer();
	const refDatalayerStore = storeToRefs(datalayerStore);

	// ***************
	// ***************
	// METHODS
	const getSize = (size = 'large') => {
		if (size === 'large') {
			return '';
		}
		if (size === 'small') {
			return 'btn-sm'
		}
		return size;
	}

	// ***************
	// ***************
	// DATA
	const props = defineProps({
    target: String,
    href: String,
    file: String,
    theme: String,
    size: String,
    label: String,
    arrow: String,
    class: String,
	});

	const targetData = props?.target || '_self';
	const hrefData = props?.file || props?.href || '';
	const themeData = props?.theme || '';
	const sizeData = getSize(props?.size || '');
	const labelData = props?.label || '';
	const arrowData = props?.arrow || '1';
	const classData = props?.class || '';
	const arrowDown = ref(false);
  if (hrefData.startsWith("#")) {
    arrowDown.value = true;
  }

	// ***************
	// ***************
	// METHODS
	const getUrlWithQuery = (el) => {
		if (!route.href.includes(el) && el?.includes('#')) {
			return `${route.href}${el}`
		}

		return $url(el);
	}

	const clickCtaButton = () => {
		if (cookieIsAllowed) {
			// prepare base object used for the data layer
			let eventTypeData = {};
			let eventData = {};

			// type
			eventTypeData.event = 'click_cta';
			// data
			eventData = {
				'page_type': refDatalayerStore?.datalayer?.value?.page_type,
				'cta_title': slugify(labelData),
			}

			const mergeDatalayer = {
				data: {
					...eventTypeData,
					...eventData
				}
			}

			datalayer(mergeDatalayer)
		}
	}
</script>