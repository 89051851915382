<template>
	<svg v-if="pageNumber > 0" width="24" height="24" viewBox="0 0 24 24" fill="none" title="chevron-small" xmlns="http://www.w3.org/2000/svg">
		<path d="M10 16L14 12L10 8" stroke="#323E48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
	</svg>

	<NuxtLink :to="$url(pageData?.link)">
		<span v-html="pageData?.title"></span>
	</NuxtLink>
</template>

<script setup>
	const { $url } = useNuxtApp();

	// props
	const props = defineProps({
		item: Object,
		index: Number,
	});
	const pageData = props?.item || {};
	const pageNumber =  props?.index || 0;
	
</script>

<style scoped lang="scss">
	span {
		text-transform: capitalize;
	}
	a {
		color: var(--bs-link-color)!important;
	}
  a:hover {
    color: currentColor;
  }
	svg {
		path {
			stroke: currentColor;
		}
	}
</style>
