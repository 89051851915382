<template>
  <div class="container" id="top-newsletter-form" ref="topNewsletterForm">
    <div class="row">
      <div class="col-12 col-lg-8 position-relative">
        <div class="card-form px-3 py-4 px-lg-4">
          <div v-if="errorfullySubmitted" class="alert alert-danger text-center" role="alert">
            {{ $t('error_submit_form') }}
          </div>

          <div v-if="successfullySubmitted" class="alert alert-success text-center" role="alert">
            {{ $t('newsletter_thank_you') }}
          </div>

          <form v-else @submit.prevent="checkForm" method="post">
            <div class="row">
              <div class="col-12">
                <strong>{{ $t('newsletter_explanation') }}</strong>
              </div>
            </div>

            <div class="row">
							<!-- fieldFirstName -->
              <div class="col-lg-6 form-floating">
                <input
									type="text"
									class="form-control"
									id="firstName"
									:placeholder="$t('first_name')"
									v-model="fieldFirstName"
									required
								>
                <label for="firstName" class="form-label">{{ $t('first_name') }}*</label>
              </div>

							<!-- fieldLastName -->
              <div class="col-lg-6 form-floating">
                <input
									type="text"
									class="form-control"
									id="lastName"
									:placeholder="$t('last_name')"
									v-model="fieldLastName"
									required
								>
                <label for="lastName" class="form-label">{{ $t('last_name') }}*</label>
              </div>

							<!-- LAN - LALPROBIOME -->
							<template
								v-if="SITE_NAME === 'lan' || SITE_NAME === 'lalprobiome'"
							>
								<div v-if="market == 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<input v-model="fieldHouseNumber" type="text" class="form-control" name="house_number" id="house_number" :placeholder="$t('lan_house_number')">
									<label for="house_number" class="form-label">{{ $t('lan_house_number') }}</label>
								</div>

								<div v-if="market == 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<input v-model="fieldTown" type="text" class="form-control" name="town" id="town" :placeholder="$t('town')" required>
									<label for="town" class="form-label">{{ $t('town') }}*</label>
								</div>

								<div v-if="market == 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<input v-model="fieldPostCode" type="text" class="form-control" name="post_code" id="post-code" :placeholder="$t('post_code')">
									<label for="post-code" class="form-label">{{ $t('post_code') }}</label>
								</div>

								<div v-if="market == 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<input v-model="fieldPhone" type="text" class="form-control" name="phone" id="phone" :placeholder="$t('phone_optional')">
									<label for="phone" class="form-label">{{ $t('phone') }} <span class="text-half-primary small-font">({{ $t('optional') }})</span></label>
								</div>
							</template>

							<!-- fieldEmail -->
              <div class="col-lg-6 form-floating">
                <input
									type="email"
									class="form-control"
									id="email"
									:placeholder="$t('email')"
									v-model="fieldEmail"
									required
								>
                <label for="email" class="form-label">{{ $t('email') }}*</label>
              </div>

							<!-- LAN - fieldCompany -->
							<template v-if="SITE_NAME === 'lan' || SITE_NAME === 'lalprobiome'">
								<div v-if="market != 'united-kingdom-ireland'" class="w-100"><hr></div>
								<div
									v-if="market != 'united-kingdom-ireland'"
									class="col-lg-6 form-floating"
								>
									<input type="text" class="form-control" id="company"  :placeholder="$t('company')" v-model="fieldCompany" required>
									<label for="company" class="form-label">{{ $t('company') }}*</label>
								</div>
							</template>

							<!-- NOT LAN - fieldCompany -->
							<template v-if="SITE_NAME !== 'lan' && SITE_NAME !== 'lalprobiome'">
								<div class="col-lg-6 form-floating">
									<input type="text" class="form-control" id="company"  :placeholder="$t('company')" v-model="fieldCompany" required>
									<label for="company" class="form-label">{{ $t('company') }}*</label>
								</div>

								<div class="w-100">
									<hr>
								</div>
							</template>

							<!-- BREWING - fieldYouAre -->
              <div
								v-if="SITE_NAME === 'brewing'"
								class="col-lg-6 form-floating"
							>
                <select class="form-select form-control w-100"  :aria-label="$t('select_function')" v-model="fieldYouAre" required id="youAre">
									<option value="">{{ $t('you_are') }} *</option>
									<option value="Business Owner">{{ $t('brew_you_are_business_owner') }}</option>
									<option value="Craft Brewer">{{ $t('brew_you_are_craft_brewer') }}</option>
									<option value="Home Brewer">{{ $t('brew_you_are_home_brewer') }}</option>
									<option value="Brewer">{{ $t('brew_you_are_brewer') }}</option>
									<option value="Home Wine Maker">{{ $t('brew_you_are_home_wine_maker') }}</option>
									<option value="Bar and restaurant owner or employee">{{ $t('brew_you_are_bar_restaurant') }}</option>
									<option value="Sales / Marketing employee">{{ $t('brew_you_are_sales_marketing') }}</option>
									<option value="Production employee">{{ $t('brew_you_are_production') }}</option>
									<option value="Administration employee">{{ $t('brew_you_are_administration') }}</option>
									<option value="R&D / QC Scientist">{{ $t('brew_you_are_rd_scientist') }}</option>
									<option value="Journalist">{{ $t('brew_you_are_journalist') }}</option>
									<option value="Influencer / Sommeliers">{{ $t('brew_you_are_influencer_sommelier') }}</option>
									<option value="Enthusiast">{{ $t('brew_you_are_enthusiast') }}</option>
									<option value="Other">{{ $t('other') }}</option>
                </select>
              </div>

							<!-- LAN - fieldYouAre - fieldCity -->
							<template
								v-if="SITE_NAME === 'lan' || SITE_NAME === 'lalprobiome'"
							>
								<div
									v-if="market != 'united-kingdom-ireland'"
									class="col-lg-6 form-floating"
								>
									<select class="form-select form-control w-100"  :aria-label="$t('select_function')" v-model="fieldYouAre" required id="youAre">
										<option value="">{{ $t('you_are') }}*</option>
										<option value="Academics">{{ $t('lan_academics') }}</option>
										<option value="Compounder">{{ $t('lan_compounder') }}</option>
										<option value="Contractors">{{ $t('lan_contractor') }}</option>
										<option value="Distributor National">{{ $t('lan_distributor_national') }}</option>
										<option value="Distributor Regional">{{ $t('lan_distributor_regional') }}</option>
										<option value="Independent Consultant">{{ $t('lan_independent_consultant') }}</option>
										<option value="Independent Nutritionists">{{ $t('lan_independent_nutritionist') }}</option>
										<option value="Pet Supplement Company">{{ $t('lan_pet_supplement_company') }}</option>
										<option value="Premixer / Mineral Feed">{{ $t('lan_premixer_mineral_feed') }}</option>
										<option value="Producer">{{ $t('lan_producer') }}</option>
										<option value="Retailer">{{ $t('lan_retailer') }}</option>
										<option value="Vets / Veterinary Laboratories">{{ $t('lan_vets_veterinary_laboratories') }}</option>
									</select>
								</div>

								<div v-if="market != 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<input type="text" class="form-control" id="city"  :placeholder="$t('city')" v-model="fieldCity">
									<label for="city" class="form-label">{{ $t('city') }}</label>
								</div>
							</template>

							<!-- fieldCountry -->
              <div class="col-lg-6 form-floating">
                <select
									class="form-select form-control w-100"
									:aria-label="$t('select_country')"
									v-model="fieldCountry"
									id="country"
									required
								>
                  <option value="">{{ $t('country') }}*</option>
                  <option
                    v-for="(country, key) in getSortedCountries()"
                    :key="country"
                    :value="country"
                    v-html="country"
                  ></option>
                </select>
							</div>

							<!-- fieldState US -->
							<div
								v-if="fieldCountry === 'United States of America'"
								class="col-lg-6 form-floating"
							>
								<select
									class="form-select form-control w-100"
									required
									:aria-label="$t('state')"
									v-model="fieldState"
									id="state"
								>
									<option selected disabled value="">{{ $t('state') }}*</option>
									<option
										v-for="(option, index) in getStates()"
										:key="`state-${index}`"
										:value="option.value"
									>
										{{ option.label }}
									</option>
								</select>
							</div>

							<!-- fieldPrefecture Japan -->
							<div
								v-if="fieldCountry === 'Japan'"
								class="col-lg-6 form-floating"
							>
								<select
									class="form-select form-control w-100"
									required
									:aria-label="$t('prefecture')"
									v-model="fieldPrefecture"
									id="prefecture"
								>
									<option selected disabled value="">{{ $t('prefecture') }}*</option>
									<option
										v-for="(option, index) in getPrefectures()"
										:key="`prefecture-${index}`"
										:value="option.value"
									>
										{{ option.label }}
									</option>
								</select>
							</div>

							<!-- LAN - fieldIndustryType - checkPreferences - preferencesUK - -->
							<template
								v-if="SITE_NAME === 'lan' || SITE_NAME === 'lalprobiome'"
							>
								<div v-if="market == 'united-kingdom-ireland'" class="col-lg-6 form-floating">
									<select v-model="fieldIndustryType"  class="form-control form-select" :aria-label="$t('industry_type')" id="industry-type" required>
										<option value="">{{ $t('lan_industry_type') }}*</option>
										<option value="Academics">{{ $t('lan_academics') }}</option>
										<option value="Compounder">{{ $t('lan_compounder') }}</option>
										<option value="Contractors">{{ $t('lan_contractor') }}</option>
										<option value="Distributor National">{{ $t('lan_distributor_national') }}</option>
										<option value="Distributor Regional">{{ $t('lan_distributor_regional') }}</option>
										<option value="Independent Consultant">{{ $t('lan_independent_consultant') }} </option>
										<option value="Independent Nutritionists">{{ $t('lan_independent_nutritionist') }}</option>
										<option value="Pet Supplement Company">{{ $t('lan_pet_supplement_company') }}</option>
										<option value="Premixer / Mineral Feed">{{ $t('lan_premixer_mineral_feed') }}</option>
										<option value="Producer">{{ $t('lan_producer') }}</option>
										<option value="Retailer">{{ $t('lan_retailer') }}</option>
										<option value="Vets / Veterinary Laboratories">{{ $t('lan_vets_veterinary_laboratories') }}</option>
									</select>
								</div>

								<div v-if="market != 'united-kingdom-ireland'" class="col-12 mb-3">
                                    <div class="w-100 mb-1" v-html="(SITE_NAME == 'lalprobiome' ? $t('lalprobiome_feed_update_preferences') : $t('lan_feed_update_preferences')) + ' *'"></div>
									<div v-for="preference in preferences" class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="preferences" :value="preference.id" v-model="checkPreferences" />
											{{ preference.name }}
										</label>
									</div>
									<div v-if="errorPreferences" class="invalid-feedback mt-1 d-block">{{ $t('lan_select_preferences') }}</div>
								</div>

								<div v-if="market == 'united-kingdom-ireland'" class="col-12 mb-3">
									<div class="w-100 mb-1">{{ $t('lan_species') }} *</div>
									<div v-for="preference in preferencesUK" class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="preferences" :value="preference.id" v-model="checkPreferences" />
											{{ preference.name }}
										</label>
									</div>
									<div v-if="errorPreferences" class="invalid-feedback mt-1 d-block">{{ $t('lan_select_preferences') }}</div>
								</div>
							</template>

							<!-- brewing - checkInterests -->
							<template
								v-if="SITE_NAME === 'brewing'"
							>
								<div class="col-12 mb-3">
									<div class="w-100 mb-1">{{ $t('brew_product_type_interests') }} *</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Bacteria for Brewing" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_bacteria_for_brewing') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Brewing Yeast" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_brewing_yeast') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Enzymes" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_enzymes') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Home Wine Yeast" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_home_wine_yeast') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Laboratory Media" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_laboratory_media') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Nutrients" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_nutrients') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Process Aids" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_process_aids') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Sensory Kits" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_sensory_kits') }}
										</label>
									</div>
									<div class="form-check form-check-inline w-100">
										<label>
											<input class="form-check-input" type="checkbox" id="interests" value="Other Product Interests" v-model="checkInterests" />
											{{ $t('brew_product_type_interest_other') }}
										</label>
									</div>
									<div v-if="errorInterests" class="invalid-feedback mt-1 d-block">{{ $t('brew_select_interests') }}</div>
								</div>
							</template>

							<!-- checkContacted - checkNewsletter -->
							<div class="col-12 mb-1" v-html="$t('consent')"></div>
							<div class="col-12 mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="contacted" value="1" v-model="checkContacted">
                  <label class="form-check-label" for="contacted">{{ $t('consent_contacted') }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="newsletter" value="1" v-model="checkNewsletter" required>
                  <label class="form-check-label" for="newsletter">{{ $t('consent_newsletter') }}*</label>
                </div>
              </div>
            </div>

						<!-- captcha -->
						<div class="col-12 mb-3">
							<vue-recaptcha ref="recaptcha"
								:sitekey="RECAPTCHA_SITE_KEY"
								@verify="onRecaptchaVerify"
								@expired="onRecaptchaVerify"
							/>
								<div class="invalid-feedback mt-2 d-block" v-if="captchaVerified">{{ $t('validation_captcha') }}</div>
						</div>

						<!-- submit -->
            <button
							type="submit"
							class="btn btn-primary btn-icon w-sm-100"
							:disabled="isSubmitting"
						>
							{{ isSubmitting ? $t('sending') : $t('send') }}
							<span class="icon-arrow">
								<ArrowIcon />
							</span>
						</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';
  import { VueRecaptcha } from 'vue-recaptcha';

	import { datalayer, getCountries } from '#imports';

  import ArrowIcon from './icons/arrow.vue';
	import { getDevice } from '../utils/device.js';

  const config = useRuntimeConfig();
	const { ACTIVE_MARKET, GTM_KEY, NUXT_PUBLIC_GTAG_ID, RECAPTCHA_SITE_KEY, SITE_NAME, SITE_URL } = config?.public || {};

	
	const { locale, t } = useI18n();
	const route = useRoute();
	const { market } = route.params;
	
	const cookie = useCookie(SITE_NAME + '-cookie_analytical');
	
	const datalayerStore = useDatalayer();
	const refDatalayerStore = storeToRefs(datalayerStore);
	
	// ***************
	// ***************
	// DATA
	const props = defineProps({
		email: String
	});

	const emailNewslettre = props?.email || '';
	const countries = getCountries() || {};

	//form
	const fieldFirstName = ref('');
	const fieldLastName = ref('');
	const fieldEmail = ref(emailNewslettre);
	const fieldCompany = ref('');
	const fieldCountry = ref('');
	const fieldState = ref('');
	const fieldPrefecture = ref('');
	const fieldYouAre = ref('');
	const checkInterests = ref([]);
	const checkNewsletter = ref(false);
  const fieldCity = ref('');
  const fieldPhone = ref('');
  const fieldHouseNumber = ref('');
	const fieldTown = ref('');
	const fieldPostCode = ref('');
	const fieldIndustryType = ref('');
	const checkPreferences = ref([]);
	const checkContacted = ref(false);

	let errorInterests = ref(false);
	let successfullySubmitted = ref(false);
	let errorfullySubmitted = ref(false);
	const captchaVerified = ref(false);
	const missingCaptcha = ref(true); // Mettre FALSE to test form on localhost
  const isSubmitting = ref(false);
  let preferences = {};
	
	const cookieIsAllowed = cookie?.value === true;

	if (SITE_NAME == 'lalprobiome') {
		preferences = [
			{
				id: 'Pet',
				name: t('lan_pet'),
			},
			{
				id: 'Equine',
				name: t('lan_equine'),
			}
		];
	} else {
		preferences = [
			{
				id: 'Silage',
				name: t('lan_silage'),
			},
			{
				id: 'Aquaculture',
				name: t('lan_aquaculture'),
			},
			{
				id: 'Poultry',
				name: t('lan_poultry'),
			},
			{
				id: 'Swine',
				name: t('lan_swine'),
			},
			{
				id: 'Ruminant',
				name: t('lan_ruminant'),
			},
			{
				id: 'Pet',
				name: t('lan_pet'),
			},
			{
				id: 'Equine',
				name: t('lan_equine'),
			},
			{
				id: 'Animal Environnment',
				name: t('lan_animal_environnment'),
			},
			{
				id: 'Blogaz',
				name: t('lan_blogaz'),
			}
		];
	}
  const preferencesUK = [
		{
			id: 'Aquaculture',
			name: t('lan_aquaculture'),
		},
		{
			id: 'Equine',
			name: t('lan_equine'),
		},
		{
			id: 'Pig',
			name: t('lan_pig'),
		},
		{
			id: 'Pet',
			name: t('lan_pet'),
		},
		{
			id: 'Poultry',
			name: t('lan_poultry'),
		},
		{
			id: 'Ruminant',
			name: t('lan_ruminant'),
		},
		{
			id: 'Silage',
			name: t('lan_silage'),
		}
  ];

	const dl_language = locale.value;
	const dl_env_channel = getDevice();

	// ***************
	// ***************
	// METHODS
	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	};

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}

			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	const onRecaptchaVerify = (response) => {
		if (response) {
			missingCaptcha.value = false;
			captchaVerified.value = false;
		} else {
			missingCaptcha.value = true;
			captchaVerified.value = true;
		}
	};

	const getPageURL = () => {
		return `${SITE_URL}${route.path}`
	}

	const checkForm = async (event) => {
	  event.preventDefault();

	  if (missingCaptcha.value) {
	    captchaVerified.value = true;
	  } else {
		  captchaVerified.value = false;
		}

    if (checkInterests.value.length > 0) {
      errorInterests.value = false;
    } else {
      errorInterests.value = true;
    }

    // if (!errorInterests.value && !missingCaptcha.value) {
		const validSubmit = () => {
			if (SITE_NAME === 'lan' || SITE_NAME === 'lalprobiome') {
				return !errorPreferences.value && !missingCaptcha.value;
			}

			if (SITE_NAME === 'brewing') {
				return !errorInterests.value && !missingCaptcha.value
			}

			return !missingCaptcha.value;
		}

    if (validSubmit()) {
			let province_state_prefecture = '';
			if (fieldCountry.value === 'United States of America') {
				province_state_prefecture = fieldState.value;
			}
			if (fieldCountry.value === 'Japan') {
				province_state_prefecture = fieldPrefecture.value;
			}

      const formData = {
        firstName: fieldFirstName.value,
        lastName: fieldLastName.value,
        email: fieldEmail.value,
        company: fieldCompany.value,

				phone: fieldPhone.value,
				house_number: fieldHouseNumber.value,
				town: fieldTown.value,
				post_code: fieldPostCode.value,
				industry_type: fieldIndustryType.value,

        youAre: fieldYouAre.value,
				city: fieldCity.value,
        country: fieldCountry.value,
        province_state: fieldState.value,

        interests: checkInterests.value,
        preferences: checkPreferences.value,
        contacted: checkContacted,
        newsletter: checkNewsletter,
				url: getPageURL(),
      };

      try {
        isSubmitting.value = true;
				console.log('`/api/form?locale=${locale.value}&market=${getCurrentMarketSlug()}`: ', `/api/form?locale=${locale.value}&market=${getCurrentMarketSlug()}`);
        const response = await $fetch(`/api/form?locale=${locale.value}&market=${getCurrentMarketSlug()}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({
              form: 'Newsletter',
              values: formData,
          }),
        });

        // Handle the response here
        if (response.form_entry == 1) {
          successfullySubmitted.value = true;

					if (cookieIsAllowed) {
						const eventTypeData = {
							'event': 'newsletter-success'
						};
						const eventData = {
							'language': dl_language,
							'env_channel': dl_env_channel,
							'customer_status': fieldYouAre?.value || 'N/A'
						};

						// if ONLY gtm
						if ( process.client && ( GTM_KEY !== null && GTM_KEY !== '' ) && ( NUXT_PUBLIC_GTAG_ID == null || NUXT_PUBLIC_GTAG_ID == '') ) {
							sendDatalayer()
							// window?.dataLayer?.push({
							// 	...eventTypeData,
							// 	...eventData
							// })
						}

						// GTAG
						if ( process.client && ( NUXT_PUBLIC_GTAG_ID !== null && NUXT_PUBLIC_GTAG_ID !== '' ) ) {
							sendDatalayer()
							// window?.dataLayer?.push({
							// 	...eventTypeData,
							// 	...eventData
							// })
							// gtag(eventTypeData, {
							// 	...eventData
							// })
						}
					}
        } else {
          errorfullySubmitted.value = true;
        }
        isSubmitting.value = false;
      } catch (error) {
        // Handle any errors that occur during the request
        console.error('Error occurred while submitting form', error);
      }
    }
  };

	// ***************
	// ***************
	// DATALAYER
	const sendDatalayer = () => {
		// prepare base object used for the data layer
		let eventTypeData = {};
		let eventData = {};

		// type
		eventTypeData.event = `newsletter_subscription`;
		// data
		eventData = {
			'page_type': refDatalayerStore?.datalayer?.value?.page_category_2
		}

		const mergeDatalayer = {
			data: {
				...eventTypeData,
				...eventData
			}
		}

		datalayer(mergeDatalayer);
	}
	
	const getSortedCountries = () => {
		let list = [];

		Object.keys(getCountries())?.forEach(function(k, i){
			list = [...list, k];
		});

		return list.sort();
	};

	 //console.log(' ');
	 // console.log(' ');
	 //console.log(' ');
	 //console.log('-- NewsletterForm --');
	 //console.log('componentData: ', componentData);
</script>