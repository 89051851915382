<template>
	<header>
		<div class="position-relative">
			<div
				class="top-banner position-relative"
				:class="[
					getTextAlignment || 'text-center',
					`${getIsHalfHeight ? 'header-short' : ''}`,
					headerData?.header_hero_background == 'image' ? 'hero' : '',
					whiteGradient == 1? 'hero-faded' : 'px-3 px-lg-100',
					SITE_NAME == 'brewing'? backgroundClass() : ''
				]"
			>
				<Breadcrumb :breadcrumb="breadcrumb" />

				<div
					v-if="headerData?.header_hero_image?.format?.orig?.[0]"
					class="top-banner__background"
				>
					<NuxtImg
						loading="lazy"
						:src="headerData?.header_hero_image?.format?.orig?.[0]"
					/>
				</div>

				<div class="top-banner__gradient">
					<div class="top-banner__gradient--item" :style="createGradient"></div>
				</div>

				<div
					v-if="getAddBactaria && getAddBactaria != '0' && SITE_NAME != 'lalprobiome'"
					class="top-banner__fixedImage"
				>
					<div class="top-banner__fixedImage--item">
						<NuxtImg
							loading="lazy"
							src="/yeast-bacteria-compressed.png"
							:style="`margin: ${getImagePosition()}; transform: ${getImageTransform()}`"
						/>
					</div>
				</div>

				<div
					class="header-text"
					:class="[
						blackGradient == 1 ? 'block-text-black-faded px-3 px-lg-100 pb-5' : '',
						whiteGradient == 1? 'block-text-white-faded px-3 px-lg-100 pb-5' : ''
					]"
				>
					<template  v-if="search != ''">
						<h1
							class="mx-auto"
							:class="[
								`text-${getTextColor || 'primary'}`
							]"
						>
							<template v-if="locale === 'ja'">
								<span class="text-lowercase">
									{{ decodeURIComponent(search) }}
								</span>
								{{ $t('search_results') }}
							</template>

							<template v-else>
								{{ $t('search_results') }}
								<span class="text-lowercase">
									{{ decodeURIComponent(search) }}
								</span>
							</template>
						</h1>
					</template>

					<template v-else>
						<h1
							v-if="getDoNotApplyH1 == '0'"
							class="mx-auto"
							:class="[
								`text-${getTextColor || 'primary'}`
							]"
							v-html="headerData?.header_hero_title || headerData?.header_hero"
						></h1>

						<div
							v-if="getDoNotApplyH1 == '1'"
							class="mx-auto h1"
							:class="[
								`text-${getTextColor || 'primary'}`
							]"
							v-html="headerData?.header_hero_title || headerData?.header_hero"
						></div>
					</template>
					
					<div
						v-if="getText && search == ''"
						v-html="getText"
						class="mt-4 mx-auto"
						:class="[
							`text-${getTextColor || 'primary'}`
						]"
					></div>

					<ButtonCta
						v-if="getAddCTA == 1"
						:target="getCTA.target"
						:href="getCTA?.link? getCTA?.link : ''"
						:file="getCTA?.file? getCTA?.file : ''"
						:theme="getCTA?.theme"
						:size="'large'"
						:label="getCTA?.label"
						:arrow="'1'"
						:class="'mt-4'"
					/>

					<div v-if="search != ''" class="position-relative search-form">
						<form class="inside-button mx-auto mt-4" method="get"  @submit.prevent="submitFormSearch">
							<input
								type="text" class="form-control"
								v-model="input"
								:placeholder="$t('search')"
								:aria-label="$t('search')"
								name="q"
							>
							<button class="btn btn-input" type="submit">
								<MagnifyIcon />
							</button>

							<button
								v-if="input" @click="input = ''"
								class="btn btn-input"
								type="button"
							>
								<EraseIcon />
							</button>
						</form>
					</div>
				</div>

				<div v-if="getAddCard == '1'" class="cta-rectangles container">
					<div class="row">
						<div 
							v-for="item in getCards"
							class="col-12 col-md-6 mb-3 mb-md-0"
						>	
							<NuxtLink
								v-if="item.header_hero_cta_card_cta_target == '_self'"
								:to="$url(item.header_hero_cta_card_cta_page_link)"
								target="_self"
							>
								<div 
									:style="`background: ${getCardBackground(item.header_hero_cta_card_background_color, item.header_hero_cta_card_background_gradient_color, item.header_hero_add_card_transparency)}`"
									class="header-rectangle p-3"
								>
									<div class="row">
										<div class="text-start d-flex"
											:class="item.header_hero_cta_card_icon_source ? 'col-10 col-md-8' : '12'"
										>
											<div
												class="rectangle-label my-auto" 
												v-html="item.header_hero_cta_card_cta_label"
											></div>
										</div>
										
										<div v-if="item.header_hero_cta_card_icon_source"  class="col-2 col-md-4 text-end">
											<span 
												class="cta-rectangle-icon"
												v-html="item.header_hero_cta_card_icon_source"
											></span>
										</div>

										<div class="col-12">
											<hr>
											<span class="float-start text-white">{{ $t('discover') }}</span>
											<span class="float-end rectangle-icon"><ArrowIcon /></span>		
										</div>
									</div>
								</div>
							</NuxtLink>

							<a
								v-if="item.header_hero_cta_card_cta_target == '_blank'"
								:href="item.header_hero_cta_card_cta_page_link"
								target="_blank"
							>
								<div 
									:style="`background: ${getCardBackground(item.header_hero_cta_card_background_color, item.header_hero_cta_card_background_gradient_color, item.header_hero_add_card_transparency)}`"
									class="header-rectangle p-3"
								>
									<div class="row">
										<div
											class="text-start d-flex"
											:class="item.header_hero_cta_card_icon_source ? 'col-10 col-md-8' : '12'"
										>
											<div
												v-html="item.header_hero_cta_card_cta_label"
												class="rectangle-label my-auto" 
											></div>
										</div>

										<div
											v-if="item.header_hero_cta_card_icon_source"
											class="col-2 col-md-4 text-end"
										>
											<span 
												class="cta-rectangle-icon"
												v-html="item.header_hero_cta_card_icon_source"
											></span>
										</div>

										<div class="col-12">
											<hr>
											<span class="float-start text-white">{{ $t('discover') }}</span>
											<span class="float-end rectangle-icon"><ArrowIcon /></span>	
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
    	</div>
  	</div>
	</header>
</template>

<script setup>
	import { getEndpointUrl } from '#imports';

	import EraseIcon from "../../icons/erase.vue";
	import MagnifyIcon from "../../icons/magnify.vue";
	import ArrowIcon from '../../icons/arrow.vue';

	const config = useRuntimeConfig();
	const SITE_NAME = config.public.SITE_NAME || '';
	const ACTIVE_MARKET = config.public.ACTIVE_MARKET || '';

	// props
	const props = defineProps({
		data: Object,
		breadcrumb: Object,
	});

	// data
	const headerData = props?.data?.data || {};
	const breadcrumb = props?.breadcrumb || {};
	const route = useRoute();
	const search = route?.query?.s ? decodeURIComponent(route.query.s) : '';
	const input = ref(decodeURIComponent(search));
	const { locale } = useI18n();
  const localePath = useLocalePath();

	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	}

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}
	
			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	const market = getActiveMarketBoolean() ? route.params.market : '';

	const layoutFetchUrl = getEndpointUrl(
		'layout', 
		getActiveMarketBoolean(),
		locale.value,
		'',
		getCurrentMarketSlug()
	);
	const { data: layoutData } = useNuxtData(layoutFetchUrl);
	if (!layoutData.value) {
		await useFetch(layoutFetchUrl, {
			key: layoutFetchUrl,
			default: () => {
				return layoutData.value;
			}
		});
	}

	// computed
	let getAddBactaria = (headerData?.header_hero_container_add_bacteria === '1' || headerData?.header_hero_container_add_bacteria === 1);
	const getIsHalfHeight = (headerData?.header_hero_half_height === '1' || headerData?.header_hero_half_height === 1);
	const getDoNotApplyH1 = headerData?.header_hero_do_not_apply_h1 || '0';
	const getText = headerData?.header_hero_text;
	const getTextColor = getTheme(headerData?.header_hero_theme);
	const getTextAlignment = headerData?.header_hero_text_alignment;
	const whiteGradient = headerData?.header_hero_image_white_gradient;
	const blackGradient = headerData?.header_hero_image_black_gradient;
	const gradientDirection = ref(headerData?.header_hero_background || '');
	const getAddCTA = headerData?.header_hero_cta_button || '0';
	const getAddCard = headerData?.header_hero_cta_card || '0';
	const getCards = headerData?.header_hero_cta_card_repeater || {};
	let getCTA = {};

	if (getAddCTA == '1') {
	  getCTA = {
			target: headerData?.header_hero_cta_target || '_self',
			link: headerData?.header_hero_cta_page_link || headerData?.header_hero_cta_page,
			file: headerData?.header_hero_cta_file || '',
			label: headerData?.header_hero_cta_label || '',
			theme: headerData?.header_hero_cta_theme || 'primary',
		}
	};

	if (search != '') {
	  getAddBactaria = "0";
	  gradientDirection.value = "top-center-gradient";
	};

	let scale = ref('scale(1.3)');
	if (typeof window !== 'undefined') {
		const screenWidth = window.innerWidth;
		if (screenWidth < 600) {
			scale.value = 'scale(1.5)';
		}
	};

  function onResize() {
		const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
			scale.value = 'scale(1.3)';
		} else {
		  scale.value = 'scale(1.1)';
		}
  };
  
  const getCardBackground = (solid, gradient, transparency) => {
		if (solid && gradient && transparency == '0') {
			return `linear-gradient(90deg, ${solid} 0%, ${gradient} 50%)`
		}
		
		if (solid && gradient && transparency == '1') {
		  const r = parseInt(gradient.slice(1, 3), 16);
			const g = parseInt(gradient.slice(3, 5), 16);
			const b = parseInt(gradient.slice(5, 7), 16);
			const transparentColor = `rgba(${r}, ${g}, ${b}, 0.9)`;
			
			return `linear-gradient(90deg, ${solid} 0%, ${transparentColor} 80%)`
		}
		
		if (solid && transparency == '1') {
			const r = parseInt(solid.slice(1, 3), 16);
			const g = parseInt(solid.slice(3, 5), 16);
			const b = parseInt(solid.slice(5, 7), 16);
			const transparentColor = `rgba(${r}, ${g}, ${b}, 0.9)`;

			return `linear-gradient(90deg, ${solid} 0%, ${transparentColor} 80%)`
		}

		if (solid) {
			return `${solid}`;
		}

		return `var(--bs-gray-200)`;
	};
	
	const backgroundClass = () => {
	  if (gradientDirection.value === "top-right-gradient" || gradientDirection.value === "top-left-gradient") {
			return `bg-full-bacteria-hero`;
		}

		return '';
	};

	const createGradient = computed(() => {
		if (gradientDirection.value === "top-right-gradient") {
			return `background: linear-gradient(42deg, rgb(255,255,255) 30%, var(--primary_rgb_light) 91%) no-repeat;`
		}
		
		if (gradientDirection.value === "top-left-gradient") {
			return `background: linear-gradient(-42deg, rgb(255,255,255) 30%, var(--primary_rgb_light) 91%) no-repeat;`
		}

		if (gradientDirection.value === "top-center-gradient") {
			return `background: radial-gradient(1055px 750px at top center, var(--primary_bg_rgb), transparent) no-repeat;`
		}

		return 'background: transparent;';
	});

	const getImagePosition = () => {
		// const gradientDirection = headerData.header_hero_background;
		if (gradientDirection.value === "top-right-gradient") {
			return `0 0 0 auto`
		}

		if (gradientDirection.value === "top-left-gradient") {
			return `0 auto 0 0`
		}

		if (gradientDirection.value === "top-center-gradient") {
			return `0 auto`
		}
	};

	const getImageTransform = () => {
		// const gradientDirection = headerData.header_hero_background;
		if (gradientDirection.value === "top-right-gradient") {
			return `translate3d(35%, -35%, 0) ` + scale.value;
		}

		if (gradientDirection.value === "top-left-gradient") {
			return `translate3d(-35%, 35%, 0) ` + scale.value;
		}

		if (gradientDirection.value === "top-center-gradient") {
			return `translate3d(0, 0, 0) ` + scale.value;
		}
	};

	const submitFormSearch = (event) => {
	  const slug = layoutData?.value?.search_page_slug;
    const url = () => {
			if (!getActiveMarketBoolean()) {
				return localePath('index') + '/' + slug + '/'
			}

			return localePath('index') + '/' + market + '/' + slug + '/'
		};

	  const variable = encodeURIComponent(input.value);

    window.location.href = url() + "?s=" + variable;
  };

  const handleKeyDown = (event) => {
		if (event.key === 'Enter' && input.value != '') {
			submitFormSearch();
		}
	};

	// methods
	onMounted(() => {
		window.addEventListener('resize', onResize);
    window.addEventListener('keydown', handleKeyDown)
  });

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onResize);
    }
    window.removeEventListener('keydown', handleKeyDown)
  });
</script>

<style lang="scss" scoped>
.cta-rectangles {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.top-banner {
	--gradient-start-offset: 0px;

	&__background {
		position: absolute;
		inset: 0;
		pointer-events: none;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__gradient {
		position: absolute;
		inset: 0;
		pointer-events: none;
		overflow: hidden;

		&--item {
			position: absolute;
			top: calc(var(--gradient-start-offset) * -1);
			height: calc(100vh - var(--gradient-start-offset));
			left: 0;
			right: 0;
		}
	}

	&__fixedImage {
		position: absolute;
		inset: 0;
		pointer-events: none;
		overflow: hidden;

		&--item {
			position: absolute;
			inset: 0;

			img {
				display: block;
				object-fit: contain;
				height: 100%;
				max-height: 450px;
				max-width: 100%;
				mask-image: radial-gradient(
					circle,
					rgba(0,0,0,1) 35%,
					rgba(0,0,0,0) 85%
				);
			}
		}
	}

	.header-text {
		position: relative;
	}
	.inside-button {
	  z-index: 1;
	}
}

.search-form {
  .inside-button {
    position: inherit;
    z-index: 1040;
  }
  input::placeholder {
    color: var(--primary);
  }
}
</style>