export const sizeOrder = [
	'x-small',
	'small',
	'medium',
	'large',
	'x-large',
	'orig',
];

export const responsiveImageBreakpoint = {
	'xs': 320,
	'sm': 640,
	'md': 768,
	'lg': 1024,
	'xl': 1280,
	'xxl': 1536,
	'2xl': 1536
}

export const responsiveImageBreakpointMap = {
	'x-small': 'xs',
	'small': 'sm',
	'medium': 'md',
	'large': 'lg',
	'x-large': 'xl',
	'orig': 'xxl',
}

export const imageSizes = (imageObject) => {
	let out = '';
	// 100vw sm:50vw md:400px
	if (imageObject?.format) {
		Object.keys(imageObject.format)?.forEach(function(key, index) {
			const imageFormat = imageObject.format[key];
			const imageWidth = imageFormat?.[1] ? imageFormat[1] : 'large';

			out = `${out} ${responsiveImageBreakpointMap[key]}:${imageWidth}px`
		});
	}

	return out
}

export const imageDefault = (imageObject) => {
	if (imageObject?.format) {
		if (imageObject.format?.[sizeOrder[0]]) {
			return imageObject.format[sizeOrder[0]];
		}
	}

	return {}
}

export const imageTitle = (imageObject) => {
	if (imageObject?.title) {
		return imageObject.title;
	}
}