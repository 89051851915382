<template>
	<div v-if="getShow">
		<HeaderTwoColumns 
			v-if="templateName === 'header-two-columns'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>

		<HeaderHero
			v-if="templateName === 'header-hero'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>

		<HeaderAnimated
			v-if="templateName === 'header-animated'" 
			:data="headerData"
		/>
			
		<HeaderEllipse
			v-if="templateName === 'header-ellipse'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>

		<HeaderContactUs
			v-if="templateName === 'header-contact-us'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>

		<HeaderArticle
			v-if="templateName === 'header-article'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>
		
		<HeaderVideo
			v-if="templateName === 'header-video'" 
			:data="headerData"
			:breadcrumb="breadcrumb"
		/>
	</div>
</template>

<script setup>
	const props = defineProps({
		data: Object,
		show: Boolean,
		breadcrumb: Object,
	});

	const templateName = props?.data?.name || '';
	const headerData = props?.data || {};
	const getShow = props?.show || true;
	const breadcrumb = props?.breadcrumb || {};

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- GetHeader ---');
	// console.log('templateName: ', templateName);
	// console.log('headerData: ', headerData);
	// console.log('getShow: ', getShow);
	// console.log('breadcrumb: ', breadcrumb);
</script>

<style lang="scss" scoped>
</style>