<template>
	<HeaderTwoColumnsTransparent
		v-if="addTransparency == '1'"
		:data="headerData"
		:breadcrumb="breadcrumb"
	/>
	<header v-if="addTransparency == '0'">
		<div class="position-relative">
			<div
				class="half-image-banner position-relative py-lg-5 px-0 px-lg-100"
				:style="`background: ${getBackground(headerData?.header_two_columns_background_color, headerData?.header_two_columns_background_gradient_color)}`"
			>
				<Breadcrumb
					:theme="getTextColor"
					:breadcrumb="breadcrumb"
				/>

				<div class="row">
					<div
						class="col-12 text-part d-flex order-2 order-lg-1"
						:class="[
							`col-lg-${headerData?.header_two_columns_image ? '5' : '12'}`
						]"
					>
						<div class="my-auto px-3 px-lg-0 py-4 py-lg-0">
							<div class="title">
								<span
									v-if="headerData?.header_two_columns_icon_source && getImageType !== 'url'"
									v-html="headerData.header_two_columns_icon_source">
								</span>

								<span v-if="headerData?.header_two_columns_icon_url?.length > 0 && getImageType == 'url'">
									<NuxtImg
										loading="lazy"
										class="icon"
										:src="headerData.header_two_columns_icon_url"
									/>
								</span>

								<h1
								  v-if="getDoNotApplyH1 == '0'"
									v-html="getTitle"
									class="mb-0 text-white title"
									:class="[
										`${getTextColor ? '' : 'text-white'}`,
										`${getTitleCssClass}`
									]"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></h1>

								<div
								  v-if="getDoNotApplyH1 == '1'"
									v-html="getTitle"
									class="mb-0 text-white title h1"
									:class="[
										`${getTextColor ? '' : 'text-white'}`,
										`${getTitleCssClass}`
									]"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></div>
							</div>

							<div class="header-text text-white">
								<div
									v-html="headerData?.header_two_columns_text"
									:style="[`${getTextColor ? `color: ${getTextColor}!important;` : ''}`]"
								></div>
							</div>

                            <div v-if="headerData?.header_two_columns_cta_target || headerData?.header_two_columns_secondary_cta_target" class="row">
                                <ButtonCta
                                  v-if="headerData?.header_two_columns_cta_target"
                                    :target="headerData?.header_two_columns_cta_target"
                                    :href="headerData?.header_two_columns_cta_page_link? headerData?.header_two_columns_cta_page_link : ''"
                                    :file="headerData?.header_two_columns_cta_page_file? headerData?.header_two_columns_cta_page_file : ''"
                                    :theme="headerData?.header_two_columns_cta_theme"
                                    :size="headerData?.header_two_columns_cta_size"
                                    :label="headerData?.header_two_columns_cta_label"
                                    :arrow="headerData?.header_two_columns_add_arrow"
                                    :class="'mt-lg-3 w-auto'"
                                />
                                <ButtonCta
                                  v-if="headerData?.header_two_columns_secondary_cta_target"
                                    :target="headerData?.header_two_columns_secondary_cta_target"
                                    :href="headerData?.header_two_columns_secondary_cta_page_link? headerData?.header_two_columns_secondary_cta_page_link : ''"
                                    :file="headerData?.header_two_columns_secondary_cta_page_file? headerData?.header_two_columns_secondary_cta_page_file : ''"
                                    :theme="headerData?.header_two_columns_secondary_cta_theme"
                                    :size="headerData?.header_two_columns_secondary_cta_size"
                                    :label="headerData?.header_two_columns_secondary_cta_label"
                                    :arrow="headerData?.header_two_columns_add_secondary_arrow"
                                    :class="'ms-sm-3 mt-2 mt-sm-0 mt-lg-3 w-auto'"
                                />
                            </div>
                        </div>
					</div>

					<div
						v-if="getImageSrc?.format?.large[0]"
						class="col-12 col-lg-5 offset-lg-2 order-1 order-lg-2 img-container"
					>
						<NuxtImg 
							loading="lazy"
							:src="getImageSrc?.format?.large[0]"
							:alt="headerData?.header_two_columns_title"
							class="banner-img w-100" 
						/>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
// **************
// **************
// hooks
const { locale } = useI18n();

// **************************
// **************************
// data
const props = defineProps({
	data: Object,
	breadcrumb: Object,
});

const headerData = props?.data?.data || {};
const breadcrumb = props?.breadcrumb || {};
const addTransparency = headerData.header_two_columns_add_transparency || '0';
const getDoNotApplyH1 = headerData?.header_two_columns_do_not_apply_h1 || '0';
const getTitle = headerData?.header_two_columns_overwrite_title ? headerData.header_two_columns_overwrite_title : headerData?.header_two_columns_title;
const getTextColor = headerData?.header_two_columns_text_color || null;
const getImageType = headerData?.header_two_columns_icon_output || 'url';
const getImageSrc = headerData?.header_two_columns_image || false;

const getTitleCssClass = computed(() => {
	if (locale.value === 'zh-hans') {
		return 'single-line-title';
	}

	return '';
});

// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log('locale.value: ', locale.value);
// console.log('getTitleCssClass: ', getTitleCssClass);

// **************************
// **************************
// methods
const getBackground = (solid, gradient) => {
	if (solid && gradient) {
		return `linear-gradient(90deg, ${solid} 0%, ${gradient} 50%)`
	}

	if (solid) {
		return `${solid}`;
	}

	return `var(--bs-gray-200)`;
}

// **************************
// **************************
// lifecycle

// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log('-- resources --');
// console.log('headerData: ', headerData);
// console.log('getImageType: ', getImageType);
</script>

<style lang="scss" scoped>
	.icon {
		max-width: 78px;
	}

	// single-line-title
	.single-line-title {
		white-space: nowrap;

		// lg +
		@include mediaq('>lg') {
			font-size: calc(1.25rem + 3.5vw)
		}

		// xl +
		@include mediaq('>xl') {
			font-size: 64px
		}
	}
</style>