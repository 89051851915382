export function getContainerBackground (background, bacteria = 0) {
  let value = '';
  switch (background) {
    case 'top-left-gradient':
      if (bacteria == 1) {
        value = 'bg-top-left-gradient-with-bacteria';
      } else {
        value = 'bg-primary-top-left-gradient';
      }
      break;
    case 'top-center-gradient':
      value = 'bg-primary-top-center-gradient';
      break;
    case 'top-right-gradient':
      if (bacteria == 1) {
        value = 'bg-top-right-gradient-with-bacteria';
      } else {
        value = 'bg-primary-top-right-gradient';
      }
      break;
    case 'bottom-left-gradient':
      if (bacteria == 1) {
        value = 'bg-bottom-left-gradient-with-bacteria';
      } else {
        value = 'bg-primary-bottom-left-gradient';
      }
      break;
    case 'bottom-center-gradient':
      value = 'bg-radial-gradient-on-bottom';
      break;
    case 'bottom-right-gradient':
      if (bacteria == 1) {
        value = 'bg-bottom-right-gradient-with-bacteria';
      } else {
        value = 'bg-primary-bottom-right-gradient';
      }
      break;
    case 'primary-gradient':
      value = 'bg-primary-gradient';
      break;
    case 'gray-gradient':
      value = 'bg-gray-gradient';
      break;
    case 'secondary-gradient':
      value = 'bg-secondary-gradient';
      break;
    case 'none':
      value = '';
      break;
    default:
      value = '';
  }
	return value;
};

export function getTheme (theme) {
  let value = '';
  switch (theme) {
    case 'primary':
      value = 'primary';
      break;
    case 'secondary':
      value = 'secondary';
      break;
    case 'light-primary':
     value = 'light-primary';
      break;
    case 'gray':
      value = 'corpo';
      break;
    case 'dark-gray':
      value = 'corpo';
      break;
    case 'white':
      value = 'white';
      break;
    case 'custom':
      value = 'custom';
      break;
    default:
      value = 'primary';
  }
	return value;
};

export function getTextColor (theme) {
  let value = '';
  switch (theme) {
    case 'primary':
      value = 'white';
      break;
    case 'secondary':
      value = 'white';
      break;
    case 'light-primary':
     value = 'primary';
      break;
    case 'gray':
      value = 'white';
      break;
    case 'dark-gray':
      value = 'white';
      break;
    case 'white':
      value = 'primary';
      break;
    case 'custom':
      value = 'custom';
      break;
    default:
      value = 'primary';
  }
	return value;
};

export function getPosition (position) {
  let value = '';
  switch (position) {
    case 'right':
      value = 'text-end';
      break;
    case 'left':
      value = 'text-start';
      break;
    case 'center':
      value = 'text-center';
      break;
    default:
      value = 'text-start';
  }
	return value;
};

export function getImageSize() {
  if (typeof window !== 'undefined') {
    // Get the size of the screen using the window object
    const screenWidth = window.innerWidth;

    // Use the screen size to determine which image to display
    if (screenWidth < 1200) {
      return 'x-large';
    } else  if (screenWidth > 1000 && screenWidth <= 1200) {
      return'large';
    } else  if (screenWidth > 800 && screenWidth <= 1000) {
      return 'medium';
    } else  if (screenWidth > 600 && screenWidth <= 800) {
      return 'small';
    } else  if (screenWidth <= 600) {
      return 'x-small';
    } else {
      return 'orig';
    }
  } else {
    return 'large';
  }
}

// @TODO @CHRIS - since this is a global function, it should have a better name
// example: getButtonSize
export function getSize(size = 'large') {
	if (size === 'large') {
		return '';
	}
	if (size === 'small') {
		return 'btn-sm'
	}
	return size;
}

export function onLinkClicked (href, event) {
	let is_image = href.endsWith(".gif") || href.endsWith(".png") || href.endsWith(".jpg") || href.endsWith(".webp") || href.endsWith(".svg");
	if (is_image) {
		event.preventDefault();
		openImageInModal(href);
	}
}

function openImageInModal(href) {
	let modal = document.querySelector('#image-content-modal');
	if (modal !== undefined) {
		let image = modal.querySelector('.modal-body img');
		if (image !== undefined) {
		  modal.classList.add('show');
		  modal.setAttribute('aria-hidden', 'false');
			image.src = href
		}
	}
}

export function getCountries(key = false) {
	const countries = {
    "China": "China",
    "India": "India",
    "United States of America": "United States of America",
    "Indonesia": "Indonesia",
    "Brazil": "Brazil",
    "Pakistan": "Pakistan",
    "Nigeria": "Nigeria",
    "Bangladesh": "Bangladesh",
    "Russian Federation": "Russian Federation",
    "Mexico": "Mexico",
    "Japan": "Japan",
    "Ethiopia": "Ethiopia",
    "Philippines": "Philippines",
    "Egypt": "Egypt",
    "Viet Nam": "Viet Nam",
    "Germany": "Germany",
    "Congo (Democratic Republic of the)": "Congo (Democratic Republic of the)",
    "Iran (Islamic Republic of)": "Iran (Islamic Republic of)",
    "Turkey": "Turkey",
    "Thailand": "Thailand",
    "France": "France",
    "United Kingdom of Great Britain and Northern Ireland": "United Kingdom of Great Britain and Northern Ireland",
    "Italy": "Italy",
    "Tanzania, United Republic of": "Tanzania, United Republic of",
    "South Africa": "South Africa",
    "Myanmar": "Myanmar",
    "Korea, Republic of": "Korea, Republic of",
    "Kenya": "Kenya",
    "Colombia": "Colombia",
    "Spain": "Spain",
    "Ukraine": "Ukraine",
    "Argentina": "Argentina",
    "Uganda": "Uganda",
    "Algeria": "Algeria",
    "Sudan": "Sudan",
    "Iraq": "Iraq",
    "Poland": "Poland",
    "Canada": "Canada",
    "Morocco": "Morocco",
    "Afghanistan": "Afghanistan",
    "Saudi Arabia": "Saudi Arabia",
    "Uzbekistan": "Uzbekistan",
    "Peru": "Peru",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Bolivarian Republic of)",
    "Malaysia": "Malaysia",
    "Angola": "Angola",
    "Mozambique": "Mozambique",
    "Nepal": "Nepal",
    "Ghana": "Ghana",
    "Yemen": "Yemen",
    "Madagascar": "Madagascar",
    "Korea (Democratic People's Republic of)": "Korea (Democratic People's Republic of)",
    "Australia": "Australia",
    "Côte d'Ivoire": "Côte d'Ivoire",
    "Cameroon": "Cameroon",
    "Niger": "Niger",
    "Sri Lanka": "Sri Lanka",
    "Romania": "Romania",
    "Burkina Faso": "Burkina Faso",
    "Malawi": "Malawi",
    "Mali": "Mali",
    "Syrian Arab Republic": "Syrian Arab Republic",
    "Chile": "Chile",
    "Kazakhstan": "Kazakhstan",
    "Netherlands": "Netherlands",
    "Zambia": "Zambia",
    "Guatemala": "Guatemala",
    "Ecuador": "Ecuador",
    "Zimbabwe": "Zimbabwe",
    "Cambodia": "Cambodia",
    "Senegal": "Senegal",
    "Chad": "Chad",
    "Somalia": "Somalia",
    "Guinea": "Guinea",
    "South Sudan": "South Sudan",
    "Rwanda": "Rwanda",
    "Tunisia": "Tunisia",
    "Cuba": "Cuba",
    "Belgium": "Belgium",
    "Benin": "Benin",
    "Bolivia (Plurinational State of)": "Bolivia (Plurinational State of)",
    "Haiti": "Haiti",
    "Burundi": "Burundi",
    "Dominican Republic": "Dominican Republic",
    "Greece": "Greece",
    "Czechia": "Czechia",
    "Portugal": "Portugal",
    "Sweden": "Sweden",
    "Azerbaijan": "Azerbaijan",
    "Hungary": "Hungary",
    "Jordan": "Jordan",
    "Belarus": "Belarus",
    "United Arab Emirates": "United Arab Emirates",
    "Honduras": "Honduras",
    "Tajikistan": "Tajikistan",
    "Austria": "Austria",
    "Israel": "Israel",
    "Switzerland": "Switzerland",
    "Papua New Guinea": "Papua New Guinea",
    "Togo": "Togo",
    "Sierra Leone": "Sierra Leone",
    "Hong Kong": "Hong Kong",
    "Bulgaria": "Bulgaria",
    "Serbia": "Serbia",
    "Lao People's Democratic Republic": "Lao People's Democratic Republic",
    "Paraguay": "Paraguay",
    "El Salvador": "El Salvador",
    "Libya": "Libya",
    "Nicaragua": "Nicaragua",
    "Kyrgyzstan": "Kyrgyzstan",
    "Lebanon": "Lebanon",
    "Denmark": "Denmark",
    "Turkmenistan": "Turkmenistan",
    "Singapore": "Singapore",
    "Finland": "Finland",
    "Slovakia": "Slovakia",
    "Norway": "Norway",
    "Congo": "Congo",
    "Costa Rica": "Costa Rica",
    "Ireland": "Ireland",
    "New Zealand": "New Zealand",
    "Liberia": "Liberia",
    "Palestine, State of": "Palestine, State of",
    "Central African Republic": "Central African Republic",
    "Oman": "Oman",
    "Mauritania": "Mauritania",
    "Kuwait": "Kuwait",
    "Croatia": "Croatia",
    "Panama": "Panama",
    "Georgia": "Georgia",
    "Moldova, Republic of": "Moldova, Republic of",
    "Bosnia and Herzegovina": "Bosnia and Herzegovina",
    "Uruguay": "Uruguay",
    "Puerto Rico": "Puerto Rico",
    "Mongolia": "Mongolia",
    "Armenia": "Armenia",
    "Jamaica": "Jamaica",
    "Albania": "Albania",
    "Lithuania": "Lithuania",
    "Qatar": "Qatar",
    "Namibia": "Namibia",
    "Botswana": "Botswana",
    "Lesotho": "Lesotho",
    "Gambia": "Gambia",
    "North Macedonia": "North Macedonia",
    "Slovenia": "Slovenia",
    "Gabon": "Gabon",
    "Latvia": "Latvia",
    "Guinea-Bissau": "Guinea-Bissau",
    "Bahrain": "Bahrain",
    "Trinidad and Tobago": "Trinidad and Tobago",
    "Eswatini": "Eswatini",
    "Estonia": "Estonia",
    "Timor-Leste": "Timor-Leste",
    "Equatorial Guinea": "Equatorial Guinea",
    "Mauritius": "Mauritius",
    "Cyprus": "Cyprus",
    "Djibouti": "Djibouti",
    "Fiji": "Fiji",
    "Comoros": "Comoros",
    "Bhutan": "Bhutan",
    "Guyana": "Guyana",
    "Macao": "Macao",
    "Montenegro": "Montenegro",
    "Solomon Islands": "Solomon Islands",
    "Luxembourg": "Luxembourg",
    "Suriname": "Suriname",
    "Cabo Verde": "Cabo Verde",
    "Malta": "Malta",
    "Maldives": "Maldives",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bahamas": "Bahamas",
    "Belize": "Belize",
    "Iceland": "Iceland",
    "Barbados": "Barbados",
    "French Polynesia": "French Polynesia",
    "New Caledonia": "New Caledonia",
    "Vanuatu": "Vanuatu",
    "Sao Tome and Principe": "Sao Tome and Principe",
    "Samoa": "Samoa",
    "Saint Lucia": "Saint Lucia",
    "Guam": "Guam",
    "Curaçao": "Curaçao",
    "Kiribati": "Kiribati",
    "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
    "Tonga": "Tonga",
    "Grenada": "Grenada",
    "Virgin Islands (U.S.)": "Virgin Islands (U.S.)",
    "Micronesia (Federated States of)": "Micronesia (Federated States of)",
    "Aruba": "Aruba",
    "Antigua and Barbuda": "Antigua and Barbuda",
    "Seychelles": "Seychelles",
    "Isle of Man": "Isle of Man",
    "Andorra": "Andorra",
    "Dominica": "Dominica",
    "Bermuda": "Bermuda",
    "Cayman Islands": "Cayman Islands",
    "Greenland": "Greenland",
    "American Samoa": "American Samoa",
    "Saint Kitts and Nevis": "Saint Kitts and Nevis",
    "Northern Mariana Islands": "Northern Mariana Islands",
    "Marshall Islands": "Marshall Islands",
    "Faroe Islands": "Faroe Islands",
    "Sint Maarten (Dutch part)": "Sint Maarten (Dutch part)",
    "Monaco": "Monaco",
    "Liechtenstein": "Liechtenstein",
    "Turks and Caicos Islands": "Turks and Caicos Islands",
    "Gibraltar": "Gibraltar",
    "San Marino": "San Marino",
    "Saint Martin (French part)": "Saint Martin (French part)",
    "Virgin Islands (British)": "Virgin Islands (British)",
    "Palau": "Palau",
    "Nauru": "Nauru",
    "Tuvalu": "Tuvalu",
    "Aaland Islands": "Aaland Islands",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarctica",
    "Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius and Saba",
    "Bouvet Island": "Bouvet Island",
    "British Indian Ocean Territory": "British Indian Ocean Territory",
    "Christmas Island": "Christmas Island",
    "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
    "Cook Islands": "Cook Islands",
    "Eritrea": "Eritrea",
    "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
    "French Guiana": "French Guiana",
    "French Southern Territories": "French Southern Territories",
    "Guadeloupe": "Guadeloupe",
    "Guernsey": "Guernsey",
    "Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
    "Holy See": "Holy See",
    "Jersey": "Jersey",
    "Martinique": "Martinique",
    "Mayotte": "Mayotte",
    "Montserrat": "Montserrat",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Island",
    "Pitcairn": "Pitcairn",
    "Réunion": "Réunion",
    "Saint Barthélemy": "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha": "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
    "South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands",
    "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
    "Taiwan, Province of China": "Taiwan, Province of China",
    "Tokelau": "Tokelau",
    "United States Minor Outlying Islands": "United States Minor Outlying Islands",
    "Wallis and Futuna": "Wallis and Futuna",
    "Western Sahara": "Western Sahara"
	}

	if (key && typeof key === 'string') {
		return countries?.[key] || null;
	}

	return countries;
}

export function getProvinces(key = false) {
	const provinces = [
    {
			"value": "AB",
			"label": "Alberta"
    },
    {
			"value": "BC",
			"label": "British Columbia"
    },
    {
			"value": "MB",
			"label": "Manitoba"
    },
    {
			"value": "NB",
			"label": "New Brunswick"
    },
    {
			"value": "NL",
			"label": "Newfoundland and Labrador"
    },
    {
			"value": "NT",
			"label": "Northwest Territories"
    },
    {
			"value": "NS",
			"label": "Nova Scotia"
    },
    {
			"value": "NU",
			"label": "Nunavut"
    },
    {
			"value": "ON",
			"label": "Ontario"
    },
    {
			"value": "PE",
			"label": "Prince Edward Island"
    },
    {
			"value": "QC",
			"label": "Quebec"
    },
    {
			"value": "SK",
			"label": "Saskatchewan"
    },
    {
			"value": "YT",
			"label": "Yukon"
    }
	];

	if (key && typeof key === 'string') {
		return provinces?.find((p) => {
			return p.value === key || p.label === key;
		});
	}

	return provinces;
}

export function getStates(key = false) {
	const states = [
    {
        "value": "AL",
        "label": "Alabama"
    },
    {
        "value": "AK",
        "label": "Alaska"
    },
    {
        "value": "AZ",
        "label": "Arizona"
    },
    {
        "value": "AR",
        "label": "Arkansas"
    },
    {
        "value": "CA",
        "label": "California"
    },
    {
        "value": "CO",
        "label": "Colorado"
    },
    {
        "value": "CT",
        "label": "Connecticut"
    },
    {
        "value": "DE",
        "label": "Delaware"
    },
    {
        "value": "DC",
        "label": "District of Columbia"
    },
    {
        "value": "FL",
        "label": "Florida"
    },
    {
        "value": "GA",
        "label": "Georgia"
    },
    {
        "value": "HI",
        "label": "Hawaii"
    },
    {
        "value": "ID",
        "label": "Idaho"
    },
    {
        "value": "IL",
        "label": "Illinois"
    },
    {
        "value": "IN",
        "label": "Indiana"
    },
    {
        "value": "IA",
        "label": "Iowa"
    },
    {
        "value": "KS",
        "label": "Kansas"
    },
    {
        "value": "KY",
        "label": "Kentucky"
    },
    {
        "value": "LA",
        "label": "Louisiana"
    },
    {
        "value": "ME",
        "label": "Maine"
    },
    {
        "value": "MD",
        "label": "Maryland"
    },
    {
        "value": "MA",
        "label": "Massachusetts"
    },
    {
        "value": "MI",
        "label": "Michigan"
    },
    {
        "value": "MN",
        "label": "Minnesota"
    },
    {
        "value": "MS",
        "label": "Mississippi"
    },
    {
        "value": "MO",
        "label": "Missouri"
    },
    {
        "value": "MT",
        "label": "Montana"
    },
    {
        "value": "NE",
        "label": "Nebraska"
    },
    {
        "value": "NV",
        "label": "Nevada"
    },
    {
        "value": "NH",
        "label": "New Hampshire"
    },
    {
        "value": "NJ",
        "label": "New Jersey"
    },
    {
        "value": "NM",
        "label": "New Mexico"
    },
    {
        "value": "NY",
        "label": "New York"
    },
    {
        "value": "NC",
        "label": "North Carolina"
    },
    {
        "value": "ND",
        "label": "North Dakota"
    },
    {
        "value": "OH",
        "label": "Ohio"
    },
    {
        "value": "OK",
        "label": "Oklahoma"
    },
    {
        "value": "OR",
        "label": "Oregon"
    },
    {
        "value": "PA",
        "label": "Pennsylvania"
    },
    {
        "value": "RI",
        "label": "Rhode Island"
    },
    {
        "value": "SC",
        "label": "South Carolina"
    },
    {
        "value": "SD",
        "label": "South Dakota"
    },
    {
        "value": "TN",
        "label": "Tennessee"
    },
    {
        "value": "TX",
        "label": "Texas"
    },
    {
        "value": "UT",
        "label": "Utah"
    },
    {
        "value": "VT",
        "label": "Vermont"
    },
    {
        "value": "VA",
        "label": "Virginia"
    },
    {
        "value": "WA",
        "label": "Washington"
    },
    {
        "value": "WV",
        "label": "West Virginia"
    },
    {
        "value": "WI",
        "label": "Wisconsin"
    },
    {
        "value": "WY",
        "label": "Wyoming"
    }
	];

	if (key && typeof key === 'string') {
		return states?.find((p) => {
			return p.value === key || p.label === key;
		});
	}

	return states;
}

export function getPrefectures(key = false) {
	const prefectures = [
    {
			"value": "Aichi",
			"label": "Aichi"
    },
    {
			"value": "Akita",
			"label": "Akita"
    },
    {
			"value": "Aomori",
			"label": "Aomori"
    },
    {
			"value": "Chiba",
			"label": "Chiba"
    },
    {
			"value": "Ehime",
			"label": "Ehime"
    },
    {
			"value": "Fukui",
			"label": "Fukui"
    },
    {
			"value": "Fukuoka",
			"label": "Fukuoka"
    },
    {
			"value": "Fukushima",
			"label": "Fukushima"
    },
    {
			"value": "Gifu",
			"label": "Gifu"
    },
    {
			"value": "Gunma",
			"label": "Gunma"
    },
    {
			"value": "Hiroshima",
			"label": "Hiroshima"
    },
    {
			"value": "Hokkaido",
			"label": "Hokkaido"
    },
    {
			"value": "Hyogo",
			"label": "Hyogo"
    },
    {
			"value": "Ibaraki",
			"label": "Ibaraki"
    },
    {
			"value": "Ishikawa",
			"label": "Ishikawa"
    },
    {
			"value": "Iwate",
			"label": "Iwate"
    },
    {
			"value": "Kagawa",
			"label": "Kagawa"
    },
    {
			"value": "Kagoshima",
			"label": "Kagoshima"
    },
    {
			"value": "Kanagawa",
			"label": "Kanagawa"
    },
    {
			"value": "Kochi",
			"label": "Kochi"
    },
    {
			"value": "Kumamoto",
			"label": "Kumamoto"
    },
    {
			"value": "Kyoto",
			"label": "Kyoto"
    },
    {
			"value": "Mie",
			"label": "Mie"
    },
    {
			"value": "Miyagi",
			"label": "Miyagi"
    },
    {
			"value": "Miyazaki",
			"label": "Miyazaki"
    },
    {
			"value": "Nagano",
			"label": "Nagano"
    },
    {
			"value": "Nagasaki",
			"label": "Nagasaki"
    },
    {
			"value": "Nara",
			"label": "Nara"
    },
    {
			"value": "Niigata",
			"label": "Niigata"
    },
    {
			"value": "Oita",
			"label": "Oita"
    },
    {
			"value": "Okayama",
			"label": "Okayama"
    },
    {
			"value": "Okinawa",
			"label": "Okinawa"
    },
    {
			"value": "Osaka",
			"label": "Osaka"
    },
    {
			"value": "Saga",
			"label": "Saga"
    },
    {
			"value": "Saitama",
			"label": "Saitama"
    },
    {
			"value": "Shiga",
			"label": "Shiga"
    },
    {
			"value": "Shimane",
			"label": "Shimane"
    },
    {
			"value": "Shizuoka",
			"label": "Shizuoka"
    },
    {
			"value": "Tochigi",
			"label": "Tochigi"
    },
    {
			"value": "Tokushima",
			"label": "Tokushima"
    },
    {
			"value": "Tokyo",
			"label": "Tokyo"
    },
    {
			"value": "Tottori",
			"label": "Tottori"
    },
    {
			"value": "Toyama",
			"label": "Toyama"
    },
    {
			"value": "Wakayama",
			"label": "Wakayama"
    },
    {
			"value": "Yamagata",
			"label": "Yamagata"
    },
    {
			"value": "Yamaguchi",
			"label": "Yamaguchi"
    },
    {
			"value": "Yamanashi",
			"label": "Yamanashi"
    }
	];

	if (key && typeof key === 'string') {
		return prefectures?.find((p) => {
			return p.value === key || p.label === key;
		});
	}

	return prefectures;
}

export function getPointOfSales(key = false) {
	const pointOfSales = [
    {
			"value": "Physical shop",
			"label": "physical_shop"
    },
		{
			"value": "Online shop",
			"label": "online_shop"
    },
		{
			"value": "Amazon",
			"label": "amazon"
    },
		{
			"value": "Other",
			"label": "other"
    },
	];

	if (key && typeof key === 'string') {
		return pointOfSales?.find((p) => {
			return p.value === key || p.label === key;
		});
	}

	return pointOfSales;
}

export function getRetailerContactLanguages(key = false) {
	const langs = [
    {
			"value": "en",
			"label": "English"
    },
		{
			"value": "fr",
			"label": "Français"
    },
		{
			"value": "es",
			"label": "Español"
    },
		{
			"value": "it",
			"label": "Italiano"
    },
	];

	if (key && typeof key === 'string') {
		return langs?.find((p) => {
			return p.value === key || p.label === key;
		});
	}

	return langs;
}

export const getMapStyles = (name = 'lan') => {
	if (name === 'brewing') {
		return [
			// label
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": '#444444'
					}
				]
			},
			// land
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": '#dde1e7'
					}
				]
			},
			// point of interest
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road labels
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road highway
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": '#5fbed5',
					}
				]
			},
			// road labels icon
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road arterial labels icon
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			// transit
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// water color
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": '#323e48'
					},
					{
						"visibility": "on"
					}
				]
			}
		]
	}
  if (name === 'dhw') {
		return [
			// label
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": '#444444'
					}
				]
			},
			// land
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": '#dde1e7'
					}
				]
			},
			// point of interest
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road labels
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road highway
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": '#5fbed5',
					}
				]
			},
			// road labels icon
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road arterial labels icon
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			// transit
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// water color
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": '#c1b7af'
					},
					{
						"visibility": "on"
					}
				]
			}
		]
	}
  if (name === 'oeno') {
		return  [
			// label
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#444444"
					}
				]
			},
			// point of interest
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": "#5fbed5",
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			// transit
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// water color
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "'#323e48'"
					},
					{
						"visibility": "on"
					}
				]
			}
		];
	}
  if (name === 'lsc') {
		return  [
			// label
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": '#444444'
					}
				]
			},
			// land
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": '#dde1e7'
					}
				]
			},
			// point of interest
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road labels
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road highway
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": '#5fbed5',
					}
				]
			},
			// road labels icon
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// road arterial labels icon
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			// transit
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			// water color
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": '#447cc7'
					},
					{
						"visibility": "on"
					}
				]
			}
		];
	}

	return [
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#444444"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#f2f2f2"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"saturation": -100
				},
				{
					"lightness": 45
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"color": "#46bcec"
				},
				{
					"visibility": "on"
				}
			]
		}
	]
};