function getUserDevice(agent) {
	if (agent.includes('mobi') ) {
		return 'mobile';
	}

	if (agent.includes('android') || agent.includes('tablet')) {
		const w = screen.availWidth;
		const h = screen.availHeight;

		if (h > w && w < 1025) {
			// Screen is higher than it’s wide, so we have portrait mode
			return 'tablet';
		}
		if (w > h && h < 1025) {
			// Screen is wider than it’s high, so we have landscape mode
			return 'tablet';
		}
 }

	return 'desktop';
}


export const getDevice = () => {
	if (process.client) {
		const userAgent = navigator.userAgent.toLowerCase();
	
		const userDevice = getUserDevice(userAgent);

		return userDevice;
	}

	return 'desktop'
}