<template>
	<div 
		class="small-font breadcrumb position-absolute d-none d-lg-block"
		:class="getRootClass"
		:style="`--bs-link-color: ${getTheme || `var(--bs-primary)`}; --bs-link-opacity: 1;`"
	>
    <template v-for="(item, index) in breadcrumbData">
      <BreadcrumbItem :item="item" :index="index" />
    </template>
	</div>
</template>

<script setup>
const localePath = useLocalePath();
const route = useRoute();

const { market } = route.params;
const { page } = route.params;

// props
const props = defineProps({
	theme: String,
	breadcrumb: Object,
	rootClass: String
});

const breadcrumbData = props?.breadcrumb || {};
const getTheme = props?.theme || '';
const getRootClass = props?.rootClass || '';

// methods
</script>

<style scoped lang="scss">
.breadcrumb {
	color: var(--bs-link-color)!important;
	z-index: 3;
}
</style>
