<template>
	<picture>
		<source
			v-for="(source) in getSources"
			:srcset="source[0]"
			:width="source[1]"
			:height="source[2]"
			:media="`(min-width: ${source[1]}px)`"
		>
		<img
			:src="`${getDefault[0]}`"
			:width="getDefault[1]"
			:height="getDefault[2]"
			:alt="componentData.title"
			:loading="getIsLazy === true ? 'lazy' : 'eager'"
		>
	</picture>
</template>

<script setup>
	import { sizeOrder } from '../utils/image.js';
	// ***************
	// ***************
	// PROPS
	const props = defineProps({
		data: Object,
		lazy: Boolean
	});
	const componentData = props?.data;
	// const smallestImageFormat = componentData.format[sizeOrder[0]];

	// const imageToBase64Url = `/api/image-to-base64?image=${smallestImageFormat[0]}`;
	// const imageData = await useFetch(imageToBase64Url, {
	// 	key: imageToBase64Url,
	// });

	// ***************
	// ***************
	// computed
	const getDefault = computed(() => {
		const smallestImageFormat = componentData.format[sizeOrder[0]];

		return smallestImageFormat
	});

	const getSources = computed(() => {
		return componentData.format 
	});

	const getIsLazy = computed(() => {
		if (props?.lazy) {
			return props.lazy
		}

		return props?.lazy;
	});
	

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- ResponsivePicture ---');
	// console.log('componentData: ', componentData);
	// console.log('getDefault: ', getDefault);
	// console.log('getIsLazy.value: ', getIsLazy.value);
</script>

<style lang="scss" scoped>
</style>